import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ObjectFieldTemplatePropertyType, ObjectFieldTemplateProps } from "@rjsf/utils";

const gridHorizontalSpacingMd: number = 8;
const gridHorizontalSpacingSm: number = 1;

export default function InfoFieldTemplate(props: ObjectFieldTemplateProps) {
  const { t } = useTranslation();

  const leftDesktopProperties: Array<ObjectFieldTemplatePropertyType> = props.properties.slice(0, 4);
  const rightDesktopProperties: Array<ObjectFieldTemplatePropertyType> = props.properties.slice(
    4,
    props.properties.length
  );

  return (
    <Grid container>
      <Grid item xs={12} md={6} pr={{ xs: 0, sm: gridHorizontalSpacingSm, md: gridHorizontalSpacingMd }}>
        <div className="booking-form__title">{t("checkout.yourPersonalInformationHeading")}</div>
        {leftDesktopProperties.map((element: ObjectFieldTemplatePropertyType) => (
          <Box key={element.name} className="property-wrapper">
            {element.content}
          </Box>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        pl={{ xs: 0, sm: gridHorizontalSpacingSm, md: gridHorizontalSpacingMd }}
        pt={{ xs: 5, sm: 5, md: 0 }}
      >
        <div className="booking-form__title">{t("checkout.additionalInformationHeading")}</div>
        {rightDesktopProperties.map((element: ObjectFieldTemplatePropertyType) => (
          <Box key={element.name} className="property-wrapper">
            {element.content}
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
