export const breakpointPerDevice = {
  phone: 600,
  tablet: 1200
};

export const getCurrentDeviceBasedOnViewport = (viewportWidth: number) => {
  let device = "";
  if (viewportWidth >= breakpointPerDevice["tablet"]) {
    device = "desktop";
  }
  if (viewportWidth > breakpointPerDevice["phone"] && viewportWidth < breakpointPerDevice["tablet"]) {
    device = "tablet";
  }
  if (viewportWidth <= breakpointPerDevice["phone"]) {
    device = "phone";
  }

  return device;
};
