import Box from "@mui/material/Box";
import { WidgetProps } from "@rjsf/utils";
// components
import AppFormInput from "../../../components/form/AppFormInputs/AppFormInput";

export default function InfoCustomInputField(props: WidgetProps) {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} mt={2}>
      <label className="form-group__label form-group__label--max-width">
        {props.schema.title} {props.required && "*"}
      </label>
      <AppFormInput
        name={props.schema.title}
        type="string"
        disabled={props.disabled}
        value={props.formData || ""}
        onChange={(event: any) => props.onChange(event.target.value)}
        required={props.required}
        error={props?.rawErrors && props?.rawErrors?.length > 0}
        dataAttribute={`input-${props.schema.title}`}
      />
    </Box>
  );
}
