export default [
  {
    featureType: "all",
    elementType: "all",
    stylers: [
      { saturation: -25 }, // Desaturate colors
      { lightness: 12 } // Adjust lightness
    ]
  }
];
