import { createTheme } from "@mui/material/styles";
import palette from "./_palette.scss";

export const theme = createTheme({
  palette: {
    primary: {
      main: palette.grayMiddle2,
      light: palette.grayMiddle,
      dark: palette.grayStandard
    },
    success: {
      main: palette.green
    },
    error: {
      main: palette.redLight,
      light: palette.redLighter,
      dark: palette.red
    },
    white: {
      main: palette.white
    },
    yellow: {
      main: palette.yellow
    },
    blue: {
      main: palette.blue
    },
    grayLight: {
      main: palette.grayLight
    },
    redOpacity01: {
      main: palette.redOpacity01
    },
    grayMiddle3Opacity075: {
      main: palette.grayMiddle3Opacity75
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        /* Define custom Typography styles here */
        root: {
          fontFamily: "Neutraface2Book",
          fontSize: "16px",
          color: palette.grayStandard
        }
      }
    }
  }
});
