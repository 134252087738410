import { greenCheckmarkIcon, errorIcon, requestedPngYellowIcon } from "../../../../common/vector";

export const getHeadingIcon = (bookingStatus: string): string | undefined => {
  switch (bookingStatus) {
    case "booked":
      return greenCheckmarkIcon.default;
    case "error":
      return errorIcon.default;
    case "cancelled":
      return errorIcon.default;
    case "processing":
      return requestedPngYellowIcon;
    default:
      return undefined;
  }
};
