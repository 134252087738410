import { allDayOfferType } from "../../../../../common/utils/helpers/globalConstants";

export const getTimeslotModalTitle = (
  isLoadingTimeslots: boolean,
  timeslots: Array<any> | undefined,
  offer: any,
  t: any
): string => {
  if (isLoadingTimeslots) {
    return "";
  }

  if (!timeslots?.length) {
    return t("offerDetails.timeslots.offerUnavailable");
  } else if (offer?.event_type === allDayOfferType) {
    return t("offerDetails.timeslots.offerAvailable");
  } else {
    const isRequestedOffer = offer?.directly_bookable === false;
    return isRequestedOffer ? t("offerDetails.timeslots.selectRequest") : t("offerDetails.timeslots.selectBook");
  }
};
