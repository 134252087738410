import { add, getDate, getMonth, getYear } from "date-fns";

// helper method for getting exact date and time in Date format
export const getExactTime = (date: Date, hours: number, minutes: number, seconds: number): Date => {
  return add(new Date(getYear(date), getMonth(date), getDate(date), hours, minutes, seconds), {
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
};
