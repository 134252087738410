export const getOptionsForSearch = (
  queryOffers: string,
  offersSearchResults: Array<any>,
  keywordOfferCode: string
): Array<any> => {
  if (queryOffers?.length > 0) {
    return [{ name: queryOffers, offer_code: keywordOfferCode }, ...offersSearchResults];
  } else {
    return offersSearchResults;
  }
};
