export const limitsPerDevice = {
  phone: 10,
  tablet: 14,
  desktop: 15
};

export const itemsPerRow = {
  phone: 1,
  tablet: 2,
  desktop: 3
};

export const getCurrentDeviceLimit = (currentDevice: string): number => {
  return currentDevice === "phone"
    ? limitsPerDevice?.phone
    : currentDevice === "tablet"
    ? limitsPerDevice?.tablet
    : limitsPerDevice?.desktop;
};
