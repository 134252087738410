import { useDropzone } from "react-dropzone";
// icons
import { alertExclMarkIcon, checkGreyIcon, closeGreyWider, infoCircleLightGrayIcon } from "../../common/vector";
// helpers
import { getStringFromListOfValidFormats } from "./helpers/getStringFromListOfValidFormats";
import { getAcceptedFiles } from "./helpers/getAcceptedFiles";

export default function AppDropzone(props: any) {
  const isDisabled = props.files?.length === props.maxFiles;
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: props.maxFiles,
    maxSize: props.maxSize,
    disabled: isDisabled,
    accept: props.acceptedFiles,
    onDrop: acceptedFiles => {
      const uniqueFilesToSet = getAcceptedFiles(props.files, acceptedFiles, props.maxFiles);
      props.setFiles(
        uniqueFilesToSet.map((file: any) =>
          Object.assign(file, {
            preview: file.preview ? file.preview : URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const handleRemoveFile = (filePathToRemove: any) => {
    const filteredFiles = props.files.filter((file: any) => {
      return file.path !== filePathToRemove;
    });
    props.setFiles(filteredFiles);
    props.onRemoveFile && props.onRemoveFile(filePathToRemove);
  };

  const renderAcceptedFileItems = () => {
    return props.files.map((file: any) => (
      <div key={file.path} className="custom-dropzone__upload-resume__box">
        <div className="custom-dropzone__upload-resume__box__accepted">
          <img src={checkGreyIcon.default} alt="check" />
          {file.path}
          <img
            src={closeGreyWider.default}
            alt="error"
            className="custom-dropzone__upload-resume__box__accepted__remove-icon"
            onClick={() => handleRemoveFile(file.path)}
          />
        </div>
      </div>
    ));
  };

  const renderFileRejectionItems = () => {
    if (fileRejections?.length > props.maxFiles) {
      return (
        <div className="custom-dropzone__upload-resume__box">
          <div className="custom-dropzone__upload-resume__box__rejected">
            <img src={alertExclMarkIcon.default} alt="error" />
            <div>you can upload maximum {props.maxFiles} files</div>
          </div>
        </div>
      );
    }

    return fileRejections.map(({ file, errors }: any) => {
      return (
        <div key={file.path} className="custom-dropzone__upload-resume__box">
          {errors.map((e: any) => (
            <div key={e.code} className="custom-dropzone__upload-resume__box__rejected">
              <img src={alertExclMarkIcon.default} alt="error" />
              {e.code === "file-invalid-type" && (
                <div>
                  {file.path} has a wrong file type (must be {getStringFromListOfValidFormats(props.acceptedFiles)})
                </div>
              )}
              {e.code === "file-too-large" && (
                <div>
                  {file.path} exceeds the maximum of {props.maxSize / 1000000} MB
                </div>
              )}
            </div>
          ))}
        </div>
      );
    });
  };

  return (
    <div className="custom-dropzone">
      <div
        {...getRootProps()}
        className={`dropzone custom-dropzone__drop-zone ${isDisabled ? "custom-dropzone__drop-zone--disabled" : ""} ${
          props.error ? "custom-dropzone__drop-zone--invalid-input" : ""
        }`}
      >
        <input {...getInputProps()} />
        <div
          className={`custom-dropzone__drop-zone__text ${
            isDisabled ? "custom-dropzone__drop-zone__text--disabled" : ""
          }`}
        >
          {isDisabled
            ? `you have reached the maximum of ${props.maxFiles} ${props.enableImagePreview ? "images" : "files"}`
            : props.dropzoneLabel}
          {!isDisabled && props.dropzoneAdditionalLabel ? (
            <div className="custom-dropzone__drop-zone__text__additional">{props.dropzoneAdditionalLabel}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <aside className="custom-dropzone__upload-resume">
        {props.files?.length > 0 && !props.enableImagePreview && renderAcceptedFileItems()}
        {fileRejections?.length > 0 && renderFileRejectionItems()}
      </aside>
      {props.enableImagePreview && (
        <>
          <div className="custom-dropzone__images-text-header">
            {props.files?.length} / {props.maxFiles} photos added
          </div>
          <div className="custom-dropzone__images">
            {props.files?.length > 0 &&
              props.files.map((file: any, index: number) => {
                return (
                  (file.type === "image/jpeg" || file.type === "image/png") && (
                    <div key={file.path} className="custom-dropzone__images__column">
                      <img src={file?.preview} alt={file?.path} />
                      <div
                        className="custom-dropzone__images__column__remove-btn"
                        onClick={() => handleRemoveFile(file.path)}
                      >
                        REMOVE
                      </div>
                      <div className="custom-dropzone__images__column__main-img-info">
                        {index === 0 && (
                          <div className="custom-dropzone__images__column__main-img-info__container">
                            <div className="custom-dropzone__images__column__main-img-info__img-wrapper">
                              <img src={infoCircleLightGrayIcon.default} alt="info" />
                            </div>
                            This is your main image
                          </div>
                        )}
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}
