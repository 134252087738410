import Slider from "react-slick";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface SbbQuickSelectionTypes {
  handleSbbQuickSelection: (offer: any) => void;
}

const settings = {
  arrows: true,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  vertical: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }
  ]
};

export default function SbbQuickSelection({ handleSbbQuickSelection }: SbbQuickSelectionTypes) {
  const { t } = useTranslation();

  // states
  const sbbQuickSelection = useSelector((state: any) => state.sbb.sbbQuickSelection);
  const sbbActiveOffer = useSelector((state: any) => state.sbb.sbbActiveOffer);

  return (
    <Box mt={{ xs: 3, sm: 2 }} mb={1}>
      <Box className="sbb-quick-selection-title">{t("transport.quickSelection")}</Box>
      <Box ml={{ xs: 2, sm: 1 }} mr={{ xs: 2, sm: 1 }}>
        {sbbQuickSelection?.length > 0 && (
          <Slider {...settings}>
            {sbbQuickSelection?.length > 0 &&
              sbbQuickSelection?.map((offer: any) => (
                <Box
                  key={offer?.code}
                  className={`sbb-quick-selection-item ${
                    offer?.code === sbbActiveOffer?.code ? "sbb-quick-selection-item__active" : ""
                  }`}
                  onClick={() => handleSbbQuickSelection(offer)}
                >
                  {offer?.name}
                </Box>
              ))}
          </Slider>
        )}
      </Box>
    </Box>
  );
}
