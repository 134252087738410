import { noImgPlaceholder } from "../../../common/vector";

const AppOffers = ({ offers, onOfferClick }: any) => {
  return (
    // TODO: IF NEEDED ADJUST IT TO DYNAMIC STYLES
    <div className="offers">
      {offers?.map((offer: any) => {
        return (
          <div key={offer?.offer_code} className="offers__offer" onClick={() => onOfferClick(offer?.offer_code)}>
            {offer?.offer_image_url ? (
              <img className="offers_offer__image" src={offer?.offer_image_url} alt="offer-option-img" />
            ) : (
              <img src={noImgPlaceholder} alt="offer-placeholder" />
            )}
            <div className="offers__offer__title"> {offer?.offer_title}</div>
          </div>
        );
      })}
    </div>
  );
};

export default AppOffers;
