interface AppInputProps {
  name?: string;
  type?: string;
  disabled?: boolean;
  value: string | undefined;
  onChange: (event: any) => void;
  required?: boolean;
  error?: boolean;
  dataAttribute?: string;
  placeholder?: string;
}

export default function AppFormInput({
  name,
  type,
  disabled,
  value,
  onChange,
  required,
  error,
  dataAttribute,
  placeholder,
  ...props
}: AppInputProps) {
  return (
    <input
      {...props}
      placeholder={placeholder}
      name={name}
      type={type}
      disabled={disabled}
      value={value}
      onChange={onChange}
      required={required}
      className={`input input--gray ${error ? "invalid__input" : ""}`}
      data-cy={dataAttribute ? dataAttribute : ""}
    />
  );
}
