import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
// stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// components
import AppModal from "../../../../components/ui/AppModal";
import CheckoutForm from "../../../../components/Payment/CheckoutForm";
// actions
import { useActions } from "../../../../common/hooks/useActions";
import { paymentActionCreators } from "../../../../common/store/modules/paymentModule";
import { sbbActionCreators } from "../sbbModule";

interface BookingPaymentModalProps {
  isOpen: boolean;
}

export default function SbbPaymentModal({ isOpen }: BookingPaymentModalProps) {
  const { t } = useTranslation();
  const [stripePromise, setStripePromise] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const language = localStorage.getItem("i18nextLng");

  // states
  const sbbBookingResponseData = useSelector((state: any) => state.sbb.sbbBookingResponseData);
  const bookingPaymentInfo = useSelector((state: any) => state.payment.bookingPaymentInfo);
  const bookingPaymentError = useSelector((state: any) => state.payment.bookingPaymentError);
  // actions
  const toggleSbbPaymentModal = useActions(sbbActionCreators?.toggleSbbPaymentModalAction, []);
  const getBookingPaymentInfo = useActions(paymentActionCreators?.getBookingPaymentInfoAction, []);
  const clearBookingPaymentInfo = useActions(paymentActionCreators?.clearBookingPaymentInfoAction, []);

  useEffect(() => {
    if (bookingPaymentError) {
      setErrorMessage(t("error.unexpected"));
    }
  }, [bookingPaymentError]);

  useEffect(() => {
    if (sbbBookingResponseData) {
      getBookingPaymentInfo(sbbBookingResponseData?.booking_code);
    }
    return () => {
      clearBookingPaymentInfo();
    };
  }, []);

  useEffect(() => {
    if (bookingPaymentInfo?.stripe_public_key) {
      // @ts-ignore
      setStripePromise(loadStripe(bookingPaymentInfo?.stripe_public_key, { locale: language }));
    }
  }, [bookingPaymentInfo]);

  const handleOpenModal = (): void => toggleSbbPaymentModal(true);

  const handleCloseModal = (): void => toggleSbbPaymentModal(false);

  return (
    <AppModal
      title={t("checkout.title")}
      modalSize="md"
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      handleOpenModal={handleOpenModal}
    >
      <Box mt={2} ml="auto" mr="auto">
        {bookingPaymentInfo?.client_secret && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: bookingPaymentInfo?.client_secret, loader: "always" }}
          >
            <CheckoutForm confirmationUrl={`${window.location.origin}/${language}/transport/sbb/confirmation`} />
          </Elements>
        )}
        {bookingPaymentError && !bookingPaymentInfo?.client_secret && <div>{errorMessage}</div>}
      </Box>
    </AppModal>
  );
}
