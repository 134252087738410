import Box from "@mui/material/Box";
import { WidgetProps } from "@rjsf/utils";
// components
import AppFormTextarea from "../../../components/form/AppFormInputs/AppFormTextarea";

export default function InfoCustomTextareaField(props: WidgetProps) {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} mt={2}>
      <label className="form-group__label form-group__label--max-width">
        {props.schema.title} {props.required && "*"}
      </label>
      <AppFormTextarea
        name={props.schema.title}
        disabled={props.disabled}
        value={props.formData || ""}
        onChange={(event: any) => props.onChange(event.target.value)}
        required={props.required}
        error={props?.rawErrors && props?.rawErrors?.length > 0}
        dataAttribute={`textarea-${props.schema.title}`}
      />
    </Box>
  );
}
