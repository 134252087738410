export const errorFieldActionTypes = {
  TOGGLE_ERROR_FIELD: "TOGGLE_ERROR_FIELD",
  RESET_ERROR_FIELD: "RESET_ERROR_FIELD"
};

export const initialState = {
  errorExists: false,
  errorField: null
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case errorFieldActionTypes.TOGGLE_ERROR_FIELD:
      return {
        ...state,
        errorExists: action.errorExists,
        errorField: action.errorField
      };
    case errorFieldActionTypes.RESET_ERROR_FIELD:
      return {
        ...state,
        errorExists: false,
        errorField: null
      };
    default:
      return state;
  }
};
export const errorFieldActionCreators = {
  resetErrorFieldAction: () => (dispatch: any) => {
    dispatch({
      type: errorFieldActionTypes.RESET_ERROR_FIELD
    });
  }
};
