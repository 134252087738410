// hook used for AppCustomRouteAsync component

import { useCallback, useRef } from "react";

const MESSAGE = {
  initial: "Search",
  noOption: "No results",
  networkError: "Network error, please try again"
};

export default function useAsyncHook(loadOptionFn: any) {
  const messageRef = useRef(MESSAGE.noOption);
  let timer = useRef();

  // different message for no options and network error
  const loadOptions = (keyword: any, callback: any) => {
    clearTimeout(timer.current);
    // @ts-ignore
    timer.current = setTimeout(() => {
      loadOptionFn(keyword)
        ?.then((options: any) => {
          if (!options?.length) {
            messageRef.current = MESSAGE.noOption;
          }
          callback(options);
        })
        .catch((err: any) => {
          messageRef.current = MESSAGE.networkError;
          callback([]);
        });
    }, 300);
  };

  return {
    noOptionMessages: useCallback(
      ({ inputValue }: any) => (inputValue ? messageRef.current : MESSAGE.initial),
      [messageRef]
    ),
    loadOptions: loadOptions
  };
}
