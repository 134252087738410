import { de, enGB, fr, it } from "date-fns/locale";

export const getDateFnsLocale = (): any => {
  const language = localStorage.getItem("i18nextLng");

  switch (language) {
    case "en":
      return enGB;
    case "de":
      return de;
    case "fr":
      return fr;
    case "it":
      return it;
    default:
      return enGB;
  }
};
