// helpers
import { addLeadingZero } from "../../common/utils/helpers/appLeadingZero";
// icons
import { arrowUp2, arrowDown2 } from "../../common/vector";

const AppTimeComponent = (props: any) => {
  return (
    <div className={`time__component ${props.customStyle ? props.customStyle : ""}`}>
      <div className="hours__component">
        <div
          onClick={() => {
            props.handleHoursChange("increase", 1);
          }}
          className="arrow__up"
        >
          <img src={arrowUp2?.default} alt="arrow-up" />
        </div>
        <div className={`time__box ${props.timeBoxSize ? "time__box--" + props.timeBoxSize : ""}`}>
          <input
            className=""
            value={addLeadingZero(props.hours)}
            onChange={event => {
              props.handleHoursChange("change", event.target.value);
            }}
          ></input>
        </div>
        <div
          onClick={() => {
            props.handleHoursChange("decrease", 1);
          }}
          className="arrow__down"
        >
          <img src={arrowDown2?.default} alt="arrowDown" />
        </div>
      </div>
      <div className="middle__component">:</div>
      <div className="minutes__component">
        <div
          onClick={() => {
            props.handleMinutesChange("increase", 5);
          }}
          className="arrow__up"
        >
          <img src={arrowUp2?.default} alt="arrowUp" />
        </div>
        <div className={`time__box ${props.timeBoxSize ? "time__box--" + props.timeBoxSize : ""}`}>
          <input
            className=""
            value={addLeadingZero(props.minutes)}
            onChange={event => {
              props.handleMinutesChange("change", event.target.value);
            }}
          ></input>
        </div>
        <div
          onClick={() => {
            props.handleMinutesChange("decrease", 5);
          }}
          className="arrow__down"
        >
          <img src={arrowDown2?.default} alt="arrowDown" />
        </div>
      </div>
    </div>
  );
};

export default AppTimeComponent;
