import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
// @ts-ignore
import { useTheme } from "@mui/material/styles";

interface GlobalLoaderProps {}

export default function GlobalLoader({}: GlobalLoaderProps) {
  const theme = useTheme();

  return (
    <Box className="global-loader">
      <CircularProgress
        sx={{
          color: theme?.palette?.grayLight?.main
        }}
      />
    </Box>
  );
}
