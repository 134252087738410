import { useRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// helpers
import { getMonthInFuture } from "../../common/utils/helpers/getMonthInFuture";
import { getDateFnsLocale } from "../../common/utils/helpers/localeHelpers/getDateFnsLocale";

// AppDatePicker custom

interface AppDatePickerCustomType {
  selectedDate: string | Date;
  setSelectedDate: (date: Date) => void;
  minDate?: Date | string;
}

export default function AppDatePickerCustom({ selectedDate, setSelectedDate, minDate }: AppDatePickerCustomType) {
  const inputRef = useRef(null);

  const handleDateChange = (date: any) => setSelectedDate(date);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateFnsLocale()}>
      <DatePicker
        ref={inputRef.current}
        minDate={minDate}
        maxDate={getMonthInFuture(6)}
        value={selectedDate}
        onChange={handleDateChange}
        sx={{ width: "100%" }}
      />
    </LocalizationProvider>
  );
}
