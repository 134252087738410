import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// helpers
import { axiosInstance } from "../../common/utils/apiHelper";
import { supportedLanguages } from "../../common/utils/translation/languagesConfig";
import i18n from "../../common/utils/translation/i18n";
// helpers
import { getHardcodedFooterLinks } from "./helpers/getHardcodedFooterLinks";
// icons
import { arrowUpLight } from "../../common/vector";
// hooks
import { useActions } from "../../common/hooks/useActions";
// actions
import { hotelActionCreators } from "../../common/store/modules/hotelModule";

interface AppFooterProps {
  isDefaultDomain: boolean;
}

const sectionLinkBoxStyle = { pb: 2, fontFamily: "Neutraface2Light" };

const date = new Date();

export default function AppFooter({ isDefaultDomain }: AppFooterProps) {
  const { language, code } = useParams<{ language: string; code: string }>();

  const { t } = useTranslation();
  const languageMenuRef = useRef<HTMLDivElement>(null);
  const languageDefault = localStorage.getItem("i18nextLng");

  // local state
  const [isOpen, setIsOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageDefault);

  // states
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  const isLanguageMenuDisabled = useSelector((state: any) => state.global.isLanguageMenuDisabled);

  // actions
  const getHotelInfo = useActions(hotelActionCreators?.getHotelInfoAction, []);

  const footerLinks = !isDefaultDomain && hotelInfo ? hotelInfo?.links : getHardcodedFooterLinks(t);
  const footerSectionGridWidthMD = footerLinks?.length > 1 ? 6 : 12;
  const footerSectionGridWidthXS = footerLinks?.length > 1 ? 6 : 12;

  useEffect(() => {
    if (hotelInfo?.code) {
      getHotelInfo(hotelInfo?.code, true);
    }
  }, [language]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  useEffect(() => {
    languageMenuRef && document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setSelectedLanguage(languageDefault);
  }, [languageDefault]);

  useEffect(() => {
    // Clone the axios instance config
    const newAxiosConfig = { ...axiosInstance.defaults };

    // Update the x-locale header with the selected language
    newAxiosConfig.headers["x-locale"] = selectedLanguage;

    // Update the axios instance with the new config
    axiosInstance.defaults = newAxiosConfig;

    if (selectedLanguage) {
      localStorage.setItem("i18nextLng", selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);

  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen);
  };

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    setIsLanguageMenuOpen(false);
  };

  // Close the menu when clicking outside it
  const handleOutsideClick = (event: MouseEvent) => {
    if (languageMenuRef.current && !languageMenuRef.current.contains(event.target as Node)) {
      setIsLanguageMenuOpen(false);
    }
  };

  return (
    <footer className="footer" data-cy="guest-footer">
      <Grid container sx={{ flexGrow: 1, py: 6, px: { sm: 0, md: 30, lg: 40 } }} className="app-container">
        <Grid container item xs={12} sm={8} md={9}>
          <Grid container item md={footerSectionGridWidthMD} xs={footerSectionGridWidthXS} justifyContent="center">
            {footerLinks?.length && (
              <Box flexDirection="column" display="flex">
                <Box sx={{ pb: 2 }}>
                  <h5 className="footer__section-title">{footerLinks[0]?.label}</h5>
                </Box>
                {footerLinks[0]?.links.map((link: any) => (
                  <Box key={link?.label} sx={sectionLinkBoxStyle}>
                    <a href={link?.link} target="_blank" rel="noreferrer" data-cy={`footer-${link?.label}`}>
                      {link?.label}
                    </a>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>

          <Grid container item md={footerSectionGridWidthMD} xs={footerSectionGridWidthXS} justifyContent="center">
            {footerLinks?.length > 1 && (
              <Box flexDirection="column" display="flex">
                <Box sx={{ pb: 2 }}>
                  <h5 className="footer__section-title">{footerLinks[1]?.label}</h5>
                </Box>
                {footerLinks[1]?.links.map((link: any) => (
                  <Box key={link?.label} sx={sectionLinkBoxStyle}>
                    <a href={link?.link} target="_blank" rel="noreferrer" data-cy={`footer-${link?.label}`}>
                      {link?.label}
                    </a>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid container item xs={6} sm={3} md={3} justifyContent="center">
          <div
            className={`footer__icon-box ${
              isLanguageMenuDisabled ? "language-box language-box--disabled" : "language-box"
            }`}
            onClick={toggleLanguageMenu}
            ref={languageMenuRef}
          >
            <span className="language-box__label">
              {supportedLanguages.find(f => f.abr == selectedLanguage)?.language}
            </span>

            <div className="language-box__arrow-box">
              <img
                className={`language-box__arrow ${
                  isLanguageMenuOpen ? "language-box__arrow--up" : "language-box__arrow--down"
                }`}
                src={arrowUpLight.default}
                alt="arrow-up"
              />
            </div>

            <div
              className={`language-menu ${isLanguageMenuOpen ? "language-menu--open" : ""} ${
                isLanguageMenuDisabled ? "language-menu--disabled" : ""
              }`}
            >
              {supportedLanguages?.map(
                language =>
                  language.abr !== selectedLanguage && (
                    <span
                      key={language.abr}
                      className="language-option"
                      onClick={() => handleLanguageChange(language.abr)}
                    >
                      {language.language}
                    </span>
                  )
              )}
            </div>
          </div>
        </Grid>

        <Grid container item xs={12} justifyContent="center" sx={{ pt: 4, px: 2 }}>
          <Box flexDirection="column" display="flex" textAlign="center">
            <p className="footer__powered-text footer__powered-text--desktop">
              {t("footer.poweredByText")} GET LOCAL | © {date.getFullYear()} GET LOCAL AG
            </p>
            <p className="footer__powered-text footer__powered-text--mobile">
              © GET LOCAL AG {hotelInfo?.name && `| ${hotelInfo?.name}`}
            </p>
            {!isDefaultDomain && (
              <p className="footer__powered-text footer__powered-text--desktop">
                {t("footer.licensedToText")} {hotelInfo?.name || "GET LOCAL"}
              </p>
            )}
          </Box>
        </Grid>
      </Grid>
    </footer>
  );
}
