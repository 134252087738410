import Grid from "@mui/material/Grid";

interface AppCardProps {
  imgSrc: string;
  imgSizes?: string;
  imgSrcSet?: string;
  leftSection?: JSX.Element;
  rightSection?: JSX.Element;
}

const sectionStyle = { display: "flex", alignItems: "center" };

export default function AppCard({ imgSrc, imgSizes, imgSrcSet, leftSection, rightSection }: AppCardProps) {
  return (
    <Grid container className="app-card">
      <Grid item xs={12} sm={12} md={4} className="app-card__image-box">
        <img src={imgSrc} sizes={imgSizes} srcSet={imgSrcSet} alt="card-image" />
      </Grid>
      <Grid item xs={12} sm={12} md={8} sx={{ flexWrap: "wrap" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} sm={rightSection ? 8 : 12} md={rightSection ? 8 : 12} sx={sectionStyle} p={3}>
            {leftSection}
          </Grid>
          {rightSection && (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={sectionStyle}
              p={{ xs: 3, md: 3 }}
              pl={{ sm: 0, md: 1 }}
              pt={{ xs: 0, md: 3 }}
            >
              {rightSection}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
