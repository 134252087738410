import { combineReducers } from "redux";

// Reducers:
import globalAppReducer from "./modules/globalModule";
import hotelModule from "./modules/hotelModule";
import errorFieldModule from "./modules/errorFieldModule";
import exploreModule from "../../pages/Explore/exploreModule";
import offerDetailsModule from "../../pages/Explore/OfferDetails/offerDetailsModule";
import bookingModule from "../../pages/Booking/bookingModule";
import paymentModule from "./modules/paymentModule";
import bookingConfirmationModule from "./modules/bookingConfirmationModule";
import sbbModule from "../../pages/PublicTransport/Sbb/sbbModule";

const rootReducer = combineReducers({
  global: globalAppReducer,
  hotel: hotelModule,
  errorField: errorFieldModule,
  explore: exploreModule,
  offerDetails: offerDetailsModule,
  booking: bookingModule,
  payment: paymentModule,
  confirmation: bookingConfirmationModule,
  sbb: sbbModule
});

export default rootReducer;
