export const getErrorMessageByStatusCode = (errorStatus: number, t: any) => {
  switch (errorStatus) {
    case 404:
      return t("error.404");
    case 500:
      return t("error.500");
    case 503:
      return t("error.503");
    case 504:
      return t("error.504");
    default:
      return t("error.404");
  }
};
