import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ObjectFieldTemplatePropertyType, ObjectFieldTemplateProps } from "@rjsf/utils";

export default function AvailabilityFieldsTemplate(props: ObjectFieldTemplateProps) {
  const leftDesktopProperties: Array<ObjectFieldTemplatePropertyType> = props.properties.slice(0, 1);
  const rightDesktopProperties: Array<ObjectFieldTemplatePropertyType> = props.properties.slice(
    1,
    props.properties.length
  );
  return (
    <Grid container className="availability">
      {leftDesktopProperties?.map((element: ObjectFieldTemplatePropertyType) => (
        // <Grid item xs={12} key={element.name} className="property-wrapper availability-date">
        <Grid item key={element.name} mt={2} mr={{ xs: 0, lg: 2 }} className="availability-date-field">
          {element.content}
        </Grid>
        // </Grid>
      ))}
      {/* <Grid item xs={12} className="availability-fields">
        <Grid container> */}
      {rightDesktopProperties?.map((element: ObjectFieldTemplatePropertyType) => (
        <Grid item key={element.name} className="property-wrapper availability-field">
          <Box mt={2} mr={{ xs: 0, lg: 2 }}>
            {element.content}
          </Box>
        </Grid>
      ))}
      {/* </Grid> */}
      {/* </Grid> */}
    </Grid>
  );
}
