import { Box } from "@mui/material";

interface HeadingBoxProps {
  icon?: string;
  title: string;
  text: string | JSX.Element;
  separator?: boolean;
}

export default function HeadingBox({ icon, title, text, separator }: HeadingBoxProps) {
  return (
    <div className="simple-heading-box">
      {icon && (
        <Box
          className="simple-heading-box__icon-box"
          sx={{ pb: 5 }}
          justifyContent="center"
          display="flex"
          alignItems="center"
        >
          <img src={icon} alt="heading-icon" />
        </Box>
      )}
      <h2 className="simple-heading-box__title">{title}</h2>
      {separator && <div className="simple-heading-box__separator" />}
      <p className="simple-heading-box__text">{text}</p>
    </div>
  );
}
