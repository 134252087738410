import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// Components
import HeadingBox from "../../components/ui/HeadingBox";
import AppInput from "../../components/form/AppInput";
import AppSubtitle from "../../components/ui/AppSubtitle";
import AppButton from "../../components/ui/AppButton";
// @ts-ignore
import { useTheme } from "@mui/material/styles";
// hooks
import { useActions } from "../../common/hooks/useActions";
// helpers
import { privacyLink, termsLink } from "../../components/AppFooter/helpers/getHardcodedFooterLinks";
// actions
import { hotelActionCreators } from "../../common/store/modules/hotelModule";

export default function Welcome() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const language = localStorage.getItem("i18nextLng");

  // local state
  const [hotelCode, setHotelCode] = useState("");

  // states
  const noHotelCodeError = useSelector((state: any) => state.hotel.noHotelCodeError);
  const isLoadingHotelInfo = useSelector((state: any) => state.hotel.isLoadingHotelInfo);

  // actions
  const getHotelInfo = useActions(hotelActionCreators?.getHotelInfoAction, []);

  useEffect(() => {
    navigate(`/${language}`, { replace: true });
  }, [language]);

  const onCodeHotelChange = (event: any) => {
    setHotelCode(event.target.value);
  };

  const onSubmitHotelCode = () => {
    getHotelInfo(hotelCode);
  };

  const onKeyUp = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      getHotelInfo(event.target.value);
    }
  };

  const formBoxStyle = { pb: 3 };

  return (
    <Box sx={{ flexGrow: 1, py: 6 }}>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={6}>
          <HeadingBox title={t("welcome.title")} text={t("welcome.text")} />
        </Grid>
      </Grid>
      <Grid sx={{ pt: { xs: 6, md: 10 } }} container justifyContent="center">
        <Grid item xs={10} md={4} data-cy="guest-login">
          <Box sx={formBoxStyle}>
            <AppSubtitle text={t("welcome.form.exploreSubtitle")} />
          </Box>
          <Box sx={formBoxStyle}>
            <AppInput
              helperText={noHotelCodeError ? t("welcome.incorrectEntry") : undefined}
              error={noHotelCodeError}
              label={t("welcome.form.exploreInputPlaceholder")}
              onChange={onCodeHotelChange}
              onKeyPress={onKeyUp}
              fullWidth
              dataAttribute="input-hotel-code"
            />
          </Box>
          <Box sx={{ height: 45 }}>
            <AppButton
              label={t("welcome.form.exploreButtonLabel")}
              onClick={onSubmitHotelCode}
              variant="contained"
              color="success"
              customBackgroundColor={theme?.palette?.success?.main}
              isLoading={isLoadingHotelInfo}
              fontSize="15px"
              dataAttribute="submit-hotel-code"
            />
          </Box>

          <Grid container display="flex" justifyContent="center">
            <Box sx={{ pt: { xs: 3, md: 5 } }} className="welcome__t-and-c-text">
              <Trans
                i18nKey={t("welcome.t&c")}
                components={{
                  termLink: <a href={termsLink} target="_blank" rel="noreferrer" />,
                  privacyLink: <a href={privacyLink} target="_blank" rel="noreferrer" />
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
