import ApiHelper from "../../utils/apiHelper";

export const hotelActionTypes = {
  SET_HOTEL_INFO: "SET_HOTEL_INFO",
  TOGGLE_HOTEL_CODE_ERROR: "TOGGLE_HOTEL_CODE_ERROR",
  TOGGLE_HOTEL_INFO_LOADER: "TOGGLE_HOTEL_INFO_LOADER"
};

export const initialState = {
  hotelInfo: null,
  noHotelCodeError: false,
  isLoadingHotelInfo: false
};

const hotelModule = (state = initialState, action: any) => {
  switch (action.type) {
    case hotelActionTypes.SET_HOTEL_INFO:
      return {
        ...state,
        hotelInfo: action.hotelInfo
      };
    case hotelActionTypes.TOGGLE_HOTEL_INFO_LOADER:
      return {
        ...state,
        isLoadingHotelInfo: action.isLoadingHotelInfo
      };
    case hotelActionTypes.TOGGLE_HOTEL_CODE_ERROR:
      return {
        ...state,
        noHotelCodeError: action.noHotelCodeError
      };

    default:
      return state;
  }
};

export default hotelModule;

export const hotelActionCreators = {
  getHotelInfoAction:
    (codeOrUrl: string, isInitialPageLoad: boolean, navigate: any) => async (dispatch: any, getState: any) => {
      const apiUrl = getState().global.apiUrl;
      const url = `${apiUrl}/hotel/info/${codeOrUrl}`;
      const language = localStorage.getItem("i18nextLng");

      // turn on hotel info loader
      dispatch({
        type: hotelActionTypes.TOGGLE_HOTEL_INFO_LOADER,
        isLoadingHotelInfo: true
      });

      try {
        const response = await ApiHelper.get(url);
        dispatch({
          type: hotelActionTypes.SET_HOTEL_INFO,
          hotelInfo: response.data
        });
        // Redirect when comes from "Welcome" Page
        if (!isInitialPageLoad) {
          window.location.hostname = `${response.data.domain}/${language}`;
        }
      } catch (err: any) {
        if (isInitialPageLoad) {
          // when comes directly to non-existing subdomain.url --- navigate to NotFound page
          navigate(`${language}/*`, {
            state: {
              errorStatus: err.response.status,
              goToMainDomain: true
            }
          });
        }
        dispatch({
          type: hotelActionTypes.TOGGLE_HOTEL_CODE_ERROR,
          noHotelCodeError: true
        });
      } finally {
        dispatch({
          type: hotelActionTypes.TOGGLE_HOTEL_INFO_LOADER,
          isLoadingHotelInfo: false
        });
      }
    }
};
