import { NavigateFunction } from "react-router-dom";
// helpers
import { getDefaultUrl } from "../getDefaultUrl";

export const navigateFromNotFoundPage = (
  goToMainDomain: boolean,
  defaultDomain: string,
  navigate: NavigateFunction,
  language?: string
): any => {
  if (goToMainDomain) {
    const mainPageUrl: string = getDefaultUrl(defaultDomain);
    window.location.replace(mainPageUrl);
    return;
  } else {
    if (language) {
      navigate(`/${language}`, { replace: true });
    } else {
      navigate(`/`, { replace: true });
    }
  }
};
