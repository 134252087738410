export const hoursUpdate = (
  setHours: (hours: number) => any,
  change: string,
  amount: string | number,
  previousHours: any
) => {
  const newAmount = Number(amount);

  const currentHours = parseInt(previousHours, 10);

  if (change === "increase") {
    const hours = currentHours > 22 ? 0 : currentHours + newAmount;
    setHours(hours);
  } else if (change === "decrease") {
    const hours = currentHours < 2 ? (currentHours < 1 ? 23 : 0) : currentHours - newAmount;
    setHours(hours);
  } else {
    setHours(newAmount > 23 ? 23 : newAmount);
  }
};
