import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
// icons
import { arrowUp } from "../../common/vector";

interface AppReadMoreProps {
  toggleCollapsible: boolean;
  size?: string;
}

export default function AppReadMore({ toggleCollapsible, size }: AppReadMoreProps) {
  const { t } = useTranslation();
  return (
    <Box className={`read-more-box ${size ? `read-more-box--${size}` : ""}`}>
      {t("readMoreLabel")}
      <img
        className={`read-more-box__arrow ${
          toggleCollapsible ? "read-more-box__arrow--up" : "read-more-box__arrow--down"
        }`}
        src={arrowUp.default}
        alt="arrow-up"
      />
    </Box>
  );
}
