import { useState } from "react";
import Box from "@mui/material/Box";
import { WidgetProps } from "@rjsf/utils";
// components
import AppDropzone from "../../../components/AppDropzone";

export const bookingDropzoneOptions = {
  maxSize: 10000000,
  maxFiles: 5,
  acceptedFiles: {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "application/pdf": [".pdf"]
  }
};

export default function InfoCustomDropzone(props: WidgetProps) {
  // TODO: this should be inside parent component or on Redux (?)
  const [bookingFiles, setBookingFiles] = useState([]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "center" }} mt={2}>
      <label className="form-group__label form-group__label--max-width">
        {props.schema.title} {props.required && "*"}
      </label>
      <AppDropzone
        error={false}
        maxFiles={bookingDropzoneOptions.maxFiles}
        maxSize={bookingDropzoneOptions.maxSize}
        acceptedFiles={bookingDropzoneOptions.acceptedFiles}
        files={bookingFiles}
        setFiles={setBookingFiles}
        dropzoneLabel={`Drag and drop files here \n or click to upload`}
      />
    </Box>
  );
}
