import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// item / icons
import { noImgPlaceholder } from "../../../common/vector";

interface AppProviderBoxProps {
  title: string;
  onClick: () => void;
  providerCover: any;
}

export default function AppProviderBox({ title, onClick, providerCover }: AppProviderBoxProps) {
  return (
    <Grid item xs={12} md={6}>
      <Box className="box-header">{title}</Box>
      <Box>
        <Box onClick={onClick} className="provider-box" data-cy="provider-box">
          {providerCover?.url ? (
            <img src={providerCover?.url} className="provider-image" alt="provider-img" />
          ) : (
            <img className="provider-image" src={noImgPlaceholder} alt="no-img-placeholder" />
          )}
        </Box>
      </Box>
    </Grid>
  );
}
