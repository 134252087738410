export const getButtonText = (apiError: boolean, bookingStatus: string, t: any): string => {
  if (apiError) {
    return t("confirmation.buttonTryAgain");
  }
  switch (bookingStatus) {
    case "booked":
      return t("confirmation.buttonExplore");
    case "error":
      return t("confirmation.buttonTryAgain");
    default:
      return t("confirmation.buttonExplore");
  }
};
