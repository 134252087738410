import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
// hooks
import { useActions } from "../../../../common/hooks/useActions";
// actions
import { sbbActionCreators } from "../sbbModule";
// helpers
import { getUpdatedArray } from "../../../../common/utils/helpers/getUpdatedArray";
// icons
import { closeDarkIcon, infoIcon, plusIcon } from "../../../../common/vector";
// components
import AppFormInput from "../../../../components/form/AppFormInputs/AppFormInput";
import AppDatePickerCustom from "../../../../components/AppDatePickerCustom";
import AppTooltip from "../../../../components/AppTooltip";

export default function SbbPassengersForm() {
  const { t } = useTranslation();

  // states
  const passengerList = useSelector((state: any) => state.sbb.passengerList);
  // actions
  const updatePassengers = useActions(sbbActionCreators?.updatePassengersAction, []);

  const handlePassengerFirstNameChange = (event: any, index: number) => {
    const updatedList = getUpdatedArray(passengerList, index, "first_name", event.target.value);
    updatePassengers(updatedList);
  };

  const handlePassengerLastNameChange = (event: any, index: number) => {
    const updatedList = getUpdatedArray(passengerList, index, "last_name", event.target.value);
    updatePassengers(updatedList);
  };

  const birthdateChange = (date: any, index: number) => {
    const updatedList = getUpdatedArray(passengerList, index, "birthdate", date);
    updatePassengers(updatedList);
  };

  const toggleFare = (index: number) => {
    const updatedList = getUpdatedArray(passengerList, index, "member_card", !passengerList[index].member_card);
    updatePassengers(updatedList);
  };

  const removePassenger = (index: number) => {
    const updatedList = passengerList?.filter((passenger: any, i: number) => i !== index);
    updatePassengers(updatedList);
  };

  const addPassengerToList = () => {
    let initialPassenger = {
      first_name: "",
      last_name: "",
      birthdate: new Date("2001-01-01"),
      member_card: false,
      hasRemove: true
    };

    updatePassengers([...passengerList, initialPassenger]);
  };

  return (
    <Box sx={{ display: "flex" }} pb={3} pt={{ xs: 5, sm: 3 }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Box className="passengers-title" pb={2}>
          {t("transport.passengers")} *
        </Box>
        <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
          <Grid container columnSpacing={{ xs: 1 }}>
            <Grid item xs={3} className="passengers-subtitle" pb={1}>
              {t("transport.formFirstName")}
            </Grid>
            <Grid item xs={3} className="passengers-subtitle" pb={1}>
              {t("transport.formLastName")}
            </Grid>
            <Grid item xs={3} className="passengers-subtitle" pb={1}>
              {t("transport.formBirthdate")}
            </Grid>
            <Grid
              item
              xs={3}
              className="passengers-subtitle"
              pb={1}
              sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}
            >
              <AppTooltip title={t("transport.halfFareBox")} placement="top-start" enterDelay={200}>
                <div>
                  <span>{t("transport.halfFareTitle")}</span>
                  <img src={infoIcon?.default} alt="half-fare" style={{ marginLeft: "6px" }} />
                </div>
              </AppTooltip>
            </Grid>
          </Grid>
        </Box>
        {passengerList?.length > 0 &&
          passengerList?.map((passenger: any) => {
            let index = passengerList.indexOf(passenger);
            return (
              <Grid container columnSpacing={{ xs: 1 }} mb={2} key={index}>
                <Grid item md={3} sm={12} xs={12} mb={{ xs: 2, md: 0 }}>
                  <AppFormInput
                    placeholder={t("transport.formFirstName")}
                    name={"first_name"}
                    type="string"
                    disabled={false}
                    value={passengerList[index]?.first_name}
                    onChange={(event: any) => {
                      handlePassengerFirstNameChange(event, index);
                    }}
                    required={true}
                    error={false}
                  />
                </Grid>
                <Grid item md={3} sm={12} xs={12} mb={{ xs: 2, md: 0 }}>
                  <AppFormInput
                    placeholder={t("transport.formLastName")}
                    name={"last_name"}
                    type="string"
                    disabled={false}
                    value={passengerList[index]?.last_name}
                    onChange={(event: any) => {
                      handlePassengerLastNameChange(event, index);
                    }}
                    required={true}
                    error={false}
                  />
                </Grid>
                <Grid item md={3} sm={12} xs={12} mb={{ xs: 2, md: 0 }}>
                  <AppDatePickerCustom
                    selectedDate={passenger?.birthdate}
                    setSelectedDate={(date: Date) => birthdateChange(date, index)}
                  />
                  <Box sx={{ display: { sm: "flex", md: "none", fontSize: "11px" } }} mt={0.5} ml={0.5}>
                    {t("transport.formBirthdate")}
                  </Box>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: { sm: "space-between", md: "flex-start" }
                    }}
                  >
                    <Switch
                      checked={passenger?.member_card}
                      onChange={() => toggleFare(index)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        "&.MuiSwitch-root .Mui-checked": {
                          color: "#7eac55"
                        },
                        "& .MuiSwitch-switchBase": {
                          transitionDuration: "300ms",
                          "&.Mui-checked": {
                            transform: "translateX(16px)",
                            color: "#fff",
                            "& + .MuiSwitch-track": {
                              backgroundColor: "#7eac55",
                              opacity: 1,
                              border: 0
                            }
                          }
                        },
                        "& .MuiSwitch-thumb": {
                          boxSizing: "border-box",
                          width: 20,
                          height: 20,
                          backgroundColor: "#575757"
                        },
                        "& .MuiSwitch-track": {
                          backgroundColor: "#e0e0e0",
                          opacity: 1
                        }
                      }}
                    />
                    <Grid
                      item
                      sx={{
                        display: { sm: "flex", md: "none" },
                        fontSize: "11px",
                        marginRight: "16px",
                        width: "100%"
                      }}
                    >
                      {t("transport.halfFareBox")}
                    </Grid>
                    <Grid item ml={{ xs: 0, sm: 3 }}>
                      {passenger?.hasRemove && (
                        <div onClick={() => removePassenger(index)} className="passenger-remove">
                          <img src={closeDarkIcon?.default} alt="close-dark" />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ display: { sm: "flex", md: "none" }, width: "100%" }} mt={1.5} mb={1}>
                  <Box sx={{ display: "flex", height: "1px", backgroundColor: "#d3d3d3", width: "100%" }} />
                </Grid>
              </Grid>
            );
          })}
        <Box onClick={addPassengerToList} sx={{ cursor: "pointer", width: "24px" }}>
          <img src={plusIcon?.default} alt="close-dark" height={24} width={24} />
        </Box>
      </Grid>
    </Box>
  );
}
