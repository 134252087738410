import { useState } from "react";
import { Box } from "@mui/material";
// images / icons
import { arrowUp } from "../../../common/vector";

type CollapsibleTypes = {
  title: string;
  children: JSX.Element;
  isExpanded: boolean;
  backgroundColor: string;
  dataAttribute?: string;
};

export default function CollapsibleBox({
  title,
  children,
  isExpanded,
  backgroundColor,
  dataAttribute
}: CollapsibleTypes) {
  // local state
  const [toggleCollapsible, setToggleCollapsible] = useState(isExpanded);

  const handleCollapsible = () => {
    setToggleCollapsible(!toggleCollapsible);
  };

  return (
    <Box className="collapsible-box" sx={{ backgroundColor: backgroundColor }}>
      <div className="collapsible-box__header" onClick={handleCollapsible} data-cy={dataAttribute ? dataAttribute : ""}>
        <span className="collapsible-box__header__title">{title}</span>
        <img
          className={
            toggleCollapsible
              ? "collapsible-box__arrow collapsible-box__arrow--up"
              : "collapsible-box__arrow collapsible-box__arrow--down"
          }
          src={arrowUp.default}
          alt="arrow-up"
        />
      </div>
      <div
        className="collapsible-box__content"
        style={{
          position: "relative",
          zIndex: "100",
          transformOrigin: "top",
          maxHeight: toggleCollapsible ? "fit-content" : "0px",
          transform: toggleCollapsible ? "scaleY(1)" : "scaleY(0)",
          opacity: toggleCollapsible ? 1 : 0,
          padding: 0,
          width: "100%",
          transition: "all .3s ease-out",
          borderTop: "none",
          marginTop: toggleCollapsible ? "15px" : "0px"
        }}
      >
        {children}
      </div>
    </Box>
  );
}
