// CustomDropzone: get list of valid formats, formatted to string
export const getStringFromListOfValidFormats = (validFormats: any) => {
  const listOfFormats = Object.values(validFormats);
  let arrOfValidFormats: Array<any> = [];

  listOfFormats.forEach((formats: any) => {
    formats.forEach((format: any) => {
      arrOfValidFormats.push(format.replace(".", ""));
    });
  });

  return arrOfValidFormats.join("/");
};
