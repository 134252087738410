import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
// icons
import { errorIcon } from "../../common/vector";
// Components
import HeadingBox from "../../components/ui/HeadingBox";
import AppButton from "../../components/ui/AppButton";
// helpers
import { getErrorMessageByStatusCode } from "./helpers/getErrorMessageByStatusCode";
import { navigateFromNotFoundPage } from "../../common/utils/helpers/navigateFromNotFoundPage";
// actions
import { globalActionCreators } from "../../common/store/modules/globalModule";

export default function NotFound() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // states
  const defaultDomain = useSelector((state: any) => state.global.defaultDomain);

  useEffect(() => {
    // TODO: try to handle turning off global loader on the global level (AppLayout ?), instead of on the component
    // not show global loader on this component
    dispatch(globalActionCreators?.toggleGlobalLoaderAction(false));
  }, []);

  const handleButtonClick = () => {
    navigateFromNotFoundPage(location?.state?.goToMainDomain, defaultDomain, navigate);
  };

  return (
    <Box sx={{ py: 8, maxWidth: 790, margin: "0 auto" }}>
      <HeadingBox
        icon={errorIcon.default}
        title={`${t("error.title")} ${location?.state?.errorStatus || ""}`}
        text={getErrorMessageByStatusCode(location?.state?.errorStatus, t)}
      />

      <Box sx={{ height: 40, minWidth: 120, maxWidth: 180, mt: 4 }} margin="0 auto">
        <AppButton
          label={t("notFound.goBack")}
          onClick={handleButtonClick}
          variant="contained"
          color="success"
          dataAttribute="not-found-button"
        />
      </Box>
    </Box>
  );
}
