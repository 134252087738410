export const getHeadingMessage = (bookingStatus: string, t: any): string => {
  switch (bookingStatus) {
    case "booked":
      return t("confirmation.bookingConfirmationMessage");
    case "error":
      return t("confirmation.bookingErrorMessage");
    case "cancelled":
      return t("confirmation.bookingCancelledMessage");
    case "processing":
      return t("confirmation.bookingProcessingMessage");
    default:
      return bookingStatus;
  }
};
