interface ThreeDotsLoaderProps {
  customClass?: string;
}

export default function ThreeDotsLoader({ customClass }: ThreeDotsLoaderProps) {
  return (
    <div className={`three-dots-loader ${customClass ? customClass : ""}`}>
      <div className="dot1"></div>
      <div className="dot2"></div>
      <div className="dot3"></div>
    </div>
  );
}
