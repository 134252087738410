import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
// @ts-ignore
import { useTheme } from "@mui/material/styles";
// components
import AppModal from "../../ui/AppModal";
import AppButton from "../../ui/AppButton";

interface AppReloadModalTypes {
  isModalOpen: boolean;
  setIsModalOpen: any;
  isMajorVersionReloadRequired: boolean;
  setShowUpdateBumpFlag: any;
}

export default function AppReloadModal({
  isModalOpen,
  setIsModalOpen,
  isMajorVersionReloadRequired,
  setShowUpdateBumpFlag
}: AppReloadModalTypes) {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <AppModal
      title={t("version.title")}
      isOpen={isModalOpen}
      // Disabled default behavior closing Modal outside of Modal window
      handleCloseModal={() => {}}
      handleOpenModal={handleOpenModal}
      showCloseIcon={false}
    >
      <Box sx={{ width: "100%" }}>
        <Box> {t("version.text.reload")} </Box>
        {!isMajorVersionReloadRequired && (
          <Box mt={2}>
            <strong>{t("version.text.continue")}</strong>
          </Box>
        )}
        <Grid container sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <Grid item width="48%" mt={4} mb={2}>
            <AppButton
              label={t("version.button.continue")}
              onClick={() => {
                setShowUpdateBumpFlag(true);
                setIsModalOpen(false);
              }}
              variant="contained"
              disableRipple={true}
              customBackgroundColor={theme.palette.primary.light}
              fontSize="14px"
              disabled={isMajorVersionReloadRequired}
              dataAttribute="version-button-continue"
            />
          </Grid>
          <Grid item width="48%" mt={4} mb={2}>
            <AppButton
              label={t("version.button.reload")}
              onClick={() => {
                localStorage.clear();
                window.location.reload();
                setIsModalOpen(false);
              }}
              variant="contained"
              disableRipple={true}
              customBackgroundColor={theme.palette.error.dark}
              fontSize="14px"
              dataAttribute="version-button-reload"
            />
          </Grid>
        </Grid>
      </Box>
    </AppModal>
  );
}
