import ApiHelper from "../../common/utils/apiHelper";
// helpers
import { getCurrentDeviceLimit } from "../../common/utils/helpers/currentDeviceHelpers/getCurrentDeviceLimit";
// import { getCurrentDevice } from "../../common/utils/helpers/currentDeviceHelpers/getCurrentDevice";
// actions
import { globalActionCreators } from "../../common/store/modules/globalModule";

// TODO: The new way of current device handling is implemented for responsive cases, consider it for related tasks and remove the line below
// const currentDevice = getCurrentDevice();

export const exploreActionTypes = {
  SET_OFFERS: "SET_OFFERS",
  SET_PAGINATION: "SET_PAGINATION",
  SET_SEARCH_RESULTS: "SET_SEARCH_RESULTS",
  SET_PROMOTED_OFFERS: "SET_PROMOTED_OFFERS",
  SET_PROMOTED_PAGINATION: "SET_PROMOTED_PAGINATION",
  SET_SELECTED_OFFER_FROM_SEARCH: "SET_SELECTED_OFFER_FROM_SEARCH",
  TOGGLE_SEARCH_RESULTS_LOADER: "TOGGLE_SEARCH_RESULTS_LOADER",
  SET_QUERY_OFFERS: "SET_QUERY_OFFERS",
  SET_SEARCHED_QUERY: "SET_SEARCHED_QUERY",
  SET_SEARCH_LOADER: "SET_SEARCH_LOADER",
  SET_TRANSPORT_OPTIONS: "SET_TRANSPORT_OPTIONS"
};

interface IActions {
  SET_EXPLORE_OFFERS: string;
}

interface IExploreOffers {
  type: IActions["SET_EXPLORE_OFFERS"];
  otherOffers?: any;
  totalResultsOtherOffers?: number;
  currentPageOtherOffers?: number;
}

type DispatchExploreOffers = (arg: IExploreOffers) => IExploreOffers;

export const initialState = {
  otherOffers: [],
  currentPageOtherOffers: 0,
  totalResultsOtherOffers: 0,
  promotedOffers: [],
  currentPagePromotedOffers: 0,
  totalResultsPromotedOffers: 0,
  totalResultsSearchedOffers: 0,
  offersSearchResults: [],
  isLoadingSearchResults: false,
  selectedOfferFromSearch: null,
  queryOffers: "", // onChange input query
  searchedQuery: "", // searched query that is selected from dropdown
  showSearchResultsLoader: false,
  transportOptions: []
};

const exploreModule = (state = initialState, action: any) => {
  switch (action.type) {
    case exploreActionTypes.SET_OFFERS:
      return {
        ...state,
        otherOffers: action.otherOffers
      };
    case exploreActionTypes.SET_PAGINATION:
      return {
        ...state,
        totalResultsOtherOffers: action.totalResultsOtherOffers,
        currentPageOtherOffers: action.currentPageOtherOffers
      };
    case exploreActionTypes.SET_PROMOTED_OFFERS:
      return {
        ...state,
        promotedOffers: action.promotedOffers
      };
    case exploreActionTypes.SET_PROMOTED_PAGINATION:
      return {
        ...state,
        totalResultsPromotedOffers: action.totalResultsPromotedOffers,
        currentPagePromotedOffers: action.currentPagePromotedOffers
      };
    case exploreActionTypes.SET_SEARCH_RESULTS:
      return {
        ...state,
        offersSearchResults: action.offersSearchResults,
        totalResultsSearchedOffers: action.totalResultsSearchedOffers
      };
    case exploreActionTypes.SET_SELECTED_OFFER_FROM_SEARCH:
      return {
        ...state,
        selectedOfferFromSearch: action.selectedOfferFromSearch
      };
    case exploreActionTypes.TOGGLE_SEARCH_RESULTS_LOADER:
      return {
        ...state,
        isLoadingSearchResults: action.isLoadingSearchResults
      };
    case exploreActionTypes.SET_QUERY_OFFERS:
      return {
        ...state,
        queryOffers: action.queryOffers
      };
    case exploreActionTypes.SET_SEARCHED_QUERY:
      return {
        ...state,
        searchedQuery: action.searchedQuery
      };
    case exploreActionTypes.SET_SEARCH_LOADER:
      return {
        ...state,
        showSearchResultsLoader: action.showSearchResultsLoader
      };
    case exploreActionTypes.SET_TRANSPORT_OPTIONS:
      return {
        ...state,
        transportOptions: action.transportOptions
      };

    default:
      return state;
  }
};

export default exploreModule;

interface getOffersActionTypes {
  keyword?: string;
  offset?: number;
  concat?: boolean; // should concat results or set new one
  device?: string; // should concat results or set new one
}

export const exploreActionCreators = {
  getPromotedOffersAction: (params: getOffersActionTypes, device: any) => async (dispatch: any, getState: any) => {
    const apiUrl = getState().global.apiUrl;
    const currentPagePromotedOffersState = getState().explore.currentPagePromotedOffers;
    const promotedOffersState = [...getState().explore.promotedOffers];

    if (getState().hotel.noHotelCodeError) {
      throw new Error("Error");
    }

    // turn on loader
    dispatch(globalActionCreators?.toggleGlobalLoaderAction(true));

    // if refreshing list (from search or category (later)) reset offset to 1
    const offset: number = params?.offset || currentPagePromotedOffersState + 1;

    const apiParams = {
      model: "list",
      hotelCode: getState().hotel.hotelInfo?.code,
      type: "top",
      limit: getCurrentDeviceLimit(device),
      offset: offset,
      keyword: params?.keyword
    };
    try {
      const response = await ApiHelper.post(`${apiUrl}/offers/list`, {}, apiParams);
      // concat list on "load more", otherwise set new list to the state
      const promotedOffers: Array<any> = params?.concat
        ? promotedOffersState.concat(response.data.result)
        : response.data.result;

      dispatch({
        type: exploreActionTypes.SET_PROMOTED_OFFERS,
        promotedOffers: promotedOffers
      });
      dispatch({
        type: exploreActionTypes.SET_PROMOTED_PAGINATION,
        totalResultsPromotedOffers: response.data.pagination.total_results || 0,
        currentPagePromotedOffers: params?.concat ? currentPagePromotedOffersState + 1 : 1
      });
    } catch (err) {
      return {};
    } finally {
      dispatch(globalActionCreators?.toggleGlobalLoaderAction(false));
    }
  },

  getOtherOffersAction:
    (params: getOffersActionTypes, device: any) => async (dispatch: DispatchExploreOffers, getState: any) => {
      const apiUrl = getState().global.apiUrl;
      const currentPageOtherOffersState = getState().explore.currentPageOtherOffers;
      const otherOffersState = [...getState().explore.otherOffers];

      if (getState().hotel.noHotelCodeError) {
        throw new Error("Error");
      }
      // turn on loader
      dispatch(globalActionCreators?.toggleGlobalLoaderAction(true));

      // if refreshing list (from search or category (later)) reset offset to 1
      const offset: number = params?.offset || currentPageOtherOffersState + 1;

      const apiParams = {
        model: "list",
        hotelCode: getState().hotel.hotelInfo?.code,
        type: "standard",
        limit: getCurrentDeviceLimit(device),
        offset: offset,
        keyword: params?.keyword
      };

      try {
        const response = await ApiHelper.post(`${apiUrl}/offers/list`, {}, apiParams);
        // concat list on "load more", otherwise set new list to the state
        const otherOffers: Array<any> = params?.concat
          ? otherOffersState.concat(response.data.result)
          : response.data.result;

        dispatch({
          type: exploreActionTypes.SET_OFFERS,
          otherOffers: otherOffers
        });
        dispatch({
          type: exploreActionTypes.SET_PAGINATION,
          totalResultsOtherOffers: response.data.pagination.total_results || 0,
          currentPageOtherOffers: params?.concat ? currentPageOtherOffersState + 1 : 1
        });
      } catch (err) {
        return {};
      } finally {
        dispatch(globalActionCreators?.toggleGlobalLoaderAction(false));
      }
    },
  getOffersAction: (params: getOffersActionTypes, device: any) => async (dispatch: any) => {
    try {
      await dispatch(exploreActionCreators?.getPromotedOffersAction(params, device));
      await dispatch(exploreActionCreators?.getOtherOffersAction(params, device));
    } catch (err) {
      return {};
    }
  },
  getTransportOptionsAction: () => async (dispatch: any, getState: any) => {
    const apiUrl = getState().global.apiUrl;

    try {
      const response = await ApiHelper.get(`${apiUrl}/transport`);

      dispatch({
        type: exploreActionTypes.SET_TRANSPORT_OPTIONS,
        transportOptions: response.data
      });
    } catch (err) {
      return {};
    }
  },
  setShowSearchResultsLoaderAction: (showSearchResultsLoader: boolean) => (dispatch: any) => {
    dispatch({
      type: exploreActionTypes.SET_SEARCH_LOADER,
      showSearchResultsLoader: showSearchResultsLoader
    });
  },
  searchOffersAutocompleteAction: (keyword: string) => async (dispatch: any, getState: any) => {
    const apiUrl = getState().global.apiUrl;

    const apiParams = {
      model: "autocomplete",
      hotelCode: getState().hotel.hotelInfo?.code,
      type: "all",
      limit: 5,
      offset: 1,
      keyword: keyword
    };
    // Turn on three dots loader
    dispatch({
      type: exploreActionTypes.TOGGLE_SEARCH_RESULTS_LOADER,
      isLoadingSearchResults: true
    });

    try {
      const response = await ApiHelper.post(`${apiUrl}/offers/list`, {}, apiParams);
      dispatch({
        type: exploreActionTypes.SET_SEARCH_RESULTS,
        offersSearchResults: response.data.result,
        totalResultsSearchedOffers: response.data.pagination.total_results || 0
      });
    } catch (err) {
      return {};
    } finally {
      // Turn off three dots loader
      dispatch(exploreActionCreators?.setShowSearchResultsLoaderAction(false));
      dispatch({
        type: exploreActionTypes.TOGGLE_SEARCH_RESULTS_LOADER,
        isLoadingSearchResults: false
      });
    }
  },
  resetSearchOffersResultsAction: () => (dispatch: any) => {
    dispatch({
      type: exploreActionTypes.SET_SEARCH_RESULTS,
      offersSearchResults: [],
      totalResultsSearchedOffers: 0
    });
  },
  setSelectedOfferFromSearchAction: (selectedOfferFromSearch: any) => (dispatch: any) => {
    dispatch({
      type: exploreActionTypes.SET_SELECTED_OFFER_FROM_SEARCH,
      selectedOfferFromSearch: selectedOfferFromSearch
    });
  },
  setQueryOffersAction: (queryOffers: string | null) => (dispatch: any) => {
    dispatch({
      type: exploreActionTypes.SET_QUERY_OFFERS,
      queryOffers
    });
  },
  setSearchedQueryAction: (searchedQuery: string) => (dispatch: any) => {
    dispatch({
      type: exploreActionTypes.SET_SEARCHED_QUERY,
      searchedQuery
    });
  },
  resetFilterOffersAction: () => (dispatch: any) => {
    // reset pagination states:
    dispatch({
      type: exploreActionTypes.SET_PROMOTED_PAGINATION,
      totalResultsPromotedOffers: 0,
      currentPagePromotedOffers: 0
    });
    dispatch({
      type: exploreActionTypes.SET_PAGINATION,
      totalResultsOtherOffers: 0,
      currentPageOtherOffers: 0
    });
    // reset offer arrays
    dispatch({
      type: exploreActionTypes.SET_OFFERS,
      otherOffers: []
    });
    dispatch({
      type: exploreActionTypes.SET_PROMOTED_OFFERS,
      promotedOffers: []
    });
    // call offers EPs with initial params
    dispatch(exploreActionCreators?.getOffersAction({ offset: 1, keyword: undefined }, ""));
    // reset selected offer
    dispatch(exploreActionCreators?.setSelectedOfferFromSearchAction({ name: "", offer_code: "", reset: true }));
    // remove query param
    dispatch({
      type: exploreActionTypes.SET_QUERY_OFFERS,
      queryOffers: ""
    });
    // remove searched query param
    dispatch({
      type: exploreActionTypes.SET_SEARCHED_QUERY,
      searchedQuery: ""
    });
  }
};
