import { Location } from "react-router-dom";
// helpers
import { mockOfferCodeKeyword } from "../../../pages/Explore/helpers/constant";
// types
import { CrumbsType } from "../types";

export const getOfferCode = (location: Location): string | undefined =>
  location?.pathname?.split("offers/")?.pop()?.split("/")[0];

export const getBreadcrumbs = (
  location: Location,
  hotelName: string,
  offerName: string,
  searchedQuery: string,
  searchResultLabel: string,
  checkoutLabel: string,
  confirmationLabel: string
): Array<CrumbsType> => {
  const offerCode = getOfferCode(location);
  const keywordHref = `?${mockOfferCodeKeyword?.toLowerCase()}=${searchedQuery}`;

  const language = localStorage.getItem("i18nextLng");

  const allBreadcrumbs: Array<CrumbsType> = [
    {
      href: `/${language}`,
      show: true,
      name: hotelName
    },
    {
      href: `/${language}${keywordHref}`,
      show: location?.search?.includes(keywordHref),
      name: searchedQuery
    },
    {
      href: `/${language}${keywordHref}`,
      show: searchedQuery?.length > 0 && location?.pathname !== "/",
      name: searchResultLabel
    },
    {
      href: `/${language}/offers/${offerCode}`,
      show: location?.pathname?.includes(`/offers/${offerCode}`),
      name: offerName
    },
    {
      href: `/${language}/offers/${offerCode}/checkout`,
      show: location?.pathname?.includes(`/offers/${offerCode}/checkout`),
      name: checkoutLabel
    },
    {
      href: `/${language}/offers/${offerCode}/checkout/confirmation`,
      show: location?.pathname?.includes(`/offers/${offerCode}/checkout/confirmation`),
      name: confirmationLabel
    },
    {
      href: `/${language}/transport/sbb`,
      show: location?.pathname?.includes(`/transport/sbb`),
      name: "SBB"
    },
    {
      href: `/${language}/transport/sbb/confirmation`,
      show: location?.pathname?.includes(`/${language}/transport/sbb/confirmation`),
      name: "Confirmation"
    }
  ];

  // filter only active breadcrumbs
  return allBreadcrumbs?.filter(crumb => crumb?.show);
};
