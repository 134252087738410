import { Box } from "@mui/material";

interface AppSummaryProps {
  label: string;
  currency: string;
  textStyle?: string;
  amount: number;
}

export default function AppSummary({ label, currency, textStyle, amount }: AppSummaryProps) {
  return (
    <Box className={`summary__details-box summary__details-box--${textStyle}`}>
      <div className="summary__details-data">{label}</div>
      <div className="summary__details-data">{currency}</div>
      <div className="summary__details-data">{amount}</div>
    </Box>
  );
}
