import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
// components
import ThreeDotsLoader from "../../ui/ThreeDotsLoader";

interface AppAutocompleteProps {
  freeSolo?: boolean;
  id?: string;
  value?: any;
  name?: string;
  onInputChange?: (event: any, value: any, reason: string) => void;
  onChangeAutoComplete?: (event: any, value: any, reason: string) => void;
  label?: string;
  getOptionLabel?: (option: any) => string;
  renderOption?: (props: any, option: any) => JSX.Element;
  noOptionsText?: string;
  options: Array<any>;
  filterOptions?: (option: any) => any;
  disableClearable?: boolean;
  loading?: boolean;
  showSearchResultsLoader?: boolean;
  size?: "medium" | "small" | string;
  handleClearButton?: () => void;
  queryOffers?: string;
  queryLimit?: number;
}

export default function AppAutocomplete({
  id,
  onInputChange,
  options,
  getOptionLabel,
  name,
  onChangeAutoComplete,
  freeSolo,
  loading,
  showSearchResultsLoader,
  noOptionsText,
  filterOptions,
  disableClearable,
  label,
  value,
  renderOption,
  size,
  handleClearButton,
  queryOffers,
  queryLimit
}: AppAutocompleteProps) {
  const renderCustomPaperList = ({ children }: any) => {
    return (
      <Paper
        sx={{
          "& .MuiAutocomplete-listbox": {
            fontFamily: "Neutraface2Book",
            fontSize: 15,
            padding: 0,
            paddingTop: 0,
            maxHeight: loading ? "auto" : options?.length > 1 ? "40vh" : "auto"
          },
          "& .explore-sidebar__autocomplete-list__footer.label": {
            padding: "0px 16px",
            paddingBottom: "8px",
            display: showSearchResultsLoader
              ? "none"
              : queryOffers && queryLimit
              ? queryOffers?.length >= queryLimit
                ? "block"
                : "none"
              : "none"
          },
          "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
            bgcolor: "#f2f2f2"
          }
        }}
      >
        {children}
      </Paper>
    );
  };

  return (
    <Autocomplete
      disablePortal={true}
      autoHighlight={true}
      id={id}
      value={value}
      onChange={onChangeAutoComplete}
      onInputChange={onInputChange}
      noOptionsText={noOptionsText}
      freeSolo={freeSolo}
      options={options}
      renderOption={renderOption}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      disableClearable={disableClearable}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: 0
        },
        "& .MuiFormLabel-root": {
          fontSize: "15px",
          fontStyle: "italic",
          fontFamily: "Neutraface2Book"
        },
        "& .MuiAutocomplete-popupIndicator": { display: "none" } // hide popup arrow icon
      }}
      PaperComponent={renderCustomPaperList}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <div className="autocomplete-endAdornment">
                  {loading && <ThreeDotsLoader />}
                  {(value?.name || queryOffers) && !loading && (
                    <IconButton
                      className="clear-button"
                      aria-label="clear"
                      onClick={handleClearButton}
                      edge="end"
                      data-cy="clear-search"
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </div>
              ),
              style: {
                fontFamily: "Neutraface2Book",
                paddingRight: "8px"
              }
            }}
            InputLabelProps={{ shrink: false }}
            label={label}
            name={name}
            size={size}
          />
        );
      }}
    />
  );
}
