import { isPast, isToday } from "date-fns";
// helpers
import { getExactTime } from "../getExactTime";

// EXPAND checkIsItInFuture WITH PAST DATA CONDITION
// Method that checks if it is the date today or in the future.
export const checkIsItInFuture = (date: Date, hours: number, minutes: number, seconds: number): boolean => {
  if (isToday(date) || isPast(date)) {
    return !isPast(getExactTime(date, hours, minutes, seconds));
  } else {
    return true;
  }
};
