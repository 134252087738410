import Grid from "@mui/material/Grid";
// components
import AppButton from "../../../../components/ui/AppButton";

interface SbbRowAction2ButtonsTypes {
  firstButtonLabel: string;
  firstButtonHandle: any;
  secondButtonLabel: string;
  secondButtonHandle: any;
  isFirstButtonActive: boolean;
}

export default function SbbRowAction2Buttons({
  firstButtonLabel,
  firstButtonHandle,
  secondButtonLabel,
  secondButtonHandle,
  isFirstButtonActive
}: SbbRowAction2ButtonsTypes) {
  return (
    <Grid item sx={{ display: "flex", flexDirection: "row", flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Grid item width={{ xs: 100, sm: 100, md: 120 }}>
        <AppButton
          label={firstButtonLabel}
          onClick={firstButtonHandle}
          disableRipple={true}
          customBackgroundColor={isFirstButtonActive ? "#3c3c3c" : "#cdcdcd"}
          customHoverColor={isFirstButtonActive ? "#3c3c3c" : "#cdcdcd"}
          labelColor={isFirstButtonActive ? "#ffffff" : "#3c3c3c"}
          type="submit"
          fontSize="13px"
        />
      </Grid>
      <Grid item width={"4%"} />
      <Grid item width={{ xs: 100, sm: 100, md: 120 }}>
        <AppButton
          label={secondButtonLabel}
          onClick={secondButtonHandle}
          disableRipple={true}
          customBackgroundColor={!isFirstButtonActive ? "#3c3c3c" : "#cdcdcd"}
          customHoverColor={!isFirstButtonActive ? "#3c3c3c" : "#cdcdcd"}
          labelColor={!isFirstButtonActive ? "#ffffff" : "#3c3c3c"}
          type="submit"
          fontSize="13px"
        />
      </Grid>
    </Grid>
  );
}
