import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
// hooks
import { useActions } from "../../common/hooks/useActions";
// actions
import { bookingActionCreators } from "./bookingModule";
// components
import AppButton from "../../components/ui/AppButton";
import HeadingBox from "../../components/ui/HeadingBox";

// icons
import { errorIcon } from "../../common/vector";

export default function PreBookingError() {
  const { t } = useTranslation();

  // states
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  // actions
  const toggleBookingError = useActions(bookingActionCreators?.toggleBookingErrorAction, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeadingBox title={t("bookingForm.error.title")} text={t("bookingForm.error.message")} icon={errorIcon.default} />
      <Box width={260} height={40} mt={5} ml="auto" mr="auto">
        <AppButton
          label={t("bookingForm.error.button")}
          onClick={() => toggleBookingError(false)}
          disableRipple={true}
          customBackgroundColor={hotelInfo?.style?.button?.color}
          customActiveColor={hotelInfo?.style?.button?.active}
          customHoverColor={hotelInfo?.style?.button?.hover}
          customClickColor={hotelInfo?.style?.button?.click}
          type="submit"
          fontSize="14px"
          dataAttribute="prebooking-error-button"
        />
      </Box>
    </>
  );
}
