export const checkVersion = (
  tagCurrentVersion: string,
  tagNewVersion: string
): { version: string; status: boolean } => {
  const major1 = tagCurrentVersion?.split(".")?.[0];
  const minor1 = tagCurrentVersion?.split(".")?.[1];
  const patch1 = tagCurrentVersion?.split(".")?.[2];

  const major2 = tagNewVersion?.split(".")?.[0];
  const minor2 = tagNewVersion?.split(".")?.[1];
  const patch2 = tagNewVersion?.split(".")?.[2];

  if (major1 < major2) {
    return { version: "major", status: true };
  }

  if (minor1 < minor2) {
    return { version: "minor", status: true };
  }

  if (patch1 < patch2) {
    return { version: "patch", status: true };
  }

  return {
    version: "",
    status: false
  };
};
