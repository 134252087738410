export const getHeadingTitle = (bookingStatus: string, t: any): string => {
  switch (bookingStatus) {
    case "booked":
      return t("confirmation.bookingConfirmed");
    case "error":
      return t("confirmation.bookingError");
    case "cancelled":
      return t("confirmation.bookingCancelled");
    case "processing":
      return t("confirmation.bookingProcessing");
    default:
      return bookingStatus;
  }
};
