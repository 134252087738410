import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
// icons
import { closeDarkIcon } from "../../../common/vector";

interface AppModalProps {
  title?: string;
  isOpen: boolean;
  modalSize?: "sm" | "md" | "lg";
  showModalNavButtons?: boolean;
  showCloseIcon?: boolean;
  handleCloseModal: (e: any) => void;
  handleOpenModal: (e: any) => void;
  children: JSX.Element;
}

export default function AppModal({
  title,
  isOpen,
  modalSize,
  showModalNavButtons,
  showCloseIcon = true,
  handleCloseModal,
  handleOpenModal,
  children
}: AppModalProps) {
  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <Box className={`app-modal ${modalSize ? `app-modal--${modalSize}` : ""}`}>
        <Box className="app-modal__header" mb={3}>
          {title && <div className="app-modal__title">{title}</div>}
          {showCloseIcon && (
            <div className="app-modal__header__close" onClick={handleCloseModal}>
              <img src={closeDarkIcon.default} alt="close-dark" />
            </div>
          )}
        </Box>

        <Typography className="app-modal__content" mt={2} component={"div"}>
          {children}
        </Typography>
        {/* TBD: Add buttons in future */}
        {/* {showModalNavButtons && (
          <Box mt={4}>
          // AppButton
          </Box>
        )} */}
      </Box>
    </Modal>
  );
}
