import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// @ts-ignore
import { useTheme } from "@mui/material/styles";

interface AppInputProps {
  label: string;
  variant?: "outlined" | "standard" | "filled";
  onChange?: (event: any) => void;
  onKeyPress?: (event: any) => void;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  dataAttribute?: string;
}

export default function AppInput({
  label,
  variant,
  onChange,
  onKeyPress,
  error,
  fullWidth,
  helperText,
  dataAttribute
}: AppInputProps) {
  const theme = useTheme();

  return (
    <Box component="form" autoComplete="off">
      <TextField
        id="outlined-basic"
        data-cy={dataAttribute ? dataAttribute : ""}
        fullWidth={fullWidth}
        label={label}
        variant={variant ? variant : "filled"}
        onChange={onChange}
        className={`app-input ${error ? "app-input--error" : ""}`}
        helperText={helperText}
        onKeyPress={onKeyPress}
        InputProps={{
          style: {
            borderRadius: "0",
            backgroundColor: error ? theme.palette.redOpacity01.main : theme.palette.grayLight.main,
            color: theme.palette.primary.dark,
            borderLeft: error ? `4px solid ${theme.palette.error.dark}` : "none"
          },
          disableUnderline: true
        }}
        sx={{
          "& .MuiFormLabel-root": {
            // input label/placeholder
            color: theme.palette.grayMiddle3Opacity075.main,
            fontStyle: "italic"
          },
          input: {
            color: theme.palette.primary.dark,
            "&::placeholder": {
              opacity: 1 // Firefox fix
            }
          }
        }}
      />
    </Box>
  );
}
