import Box from "@mui/material/Box";
import GoogleMapReact from "google-map-react";
import { useTranslation } from "react-i18next";
// map style
import googleMapStyle from "./googleMapStyle";
import { mapMarkerRedIcon } from "../../common/vector";

interface AppGoogleMapProps {
  apiKey: string;
  zoom?: number;
  lat: number;
  lng: number;
  showMarker?: boolean;
}

export default function AppGoogleMap({ apiKey, zoom, lat, lng, showMarker }: AppGoogleMapProps) {
  const { t } = useTranslation();

  const setMapOptions = (map: any) => {
    map?.event?.trigger(map, "resize");
    return {
      styles: googleMapStyle,
      zoomControlOptions: {
        position: map.ControlPosition.LEFT_BOTTOM,
        style: map.ZoomControlStyle.SMALL
      },
      fullscreenControl: false
    };
  };

  const Marker = ({}: any) => (
    <Box
      sx={{
        position: "absolute",
        top: -42,
        left: -17
      }}
    >
      <img src={mapMarkerRedIcon?.default} alt="marker" />
    </Box>
  );

  const handleOpenInGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${lat},${lng}&hl=en`;
    window.open(url, "_blank");
  };

  return (
    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        center={{ lat: lat, lng: lng }}
        zoom={zoom ? zoom : 17}
        options={setMapOptions}
      >
        {showMarker && <Marker lat={lat} lng={lng} zoom={zoom} />}
      </GoogleMapReact>
      <button className="google-map__btn" onClick={handleOpenInGoogleMaps}>
        {t("maps.openGoogleMaps")}
      </button>
    </div>
  );
}
