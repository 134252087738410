import { customizeValidator } from "@rjsf/validator-ajv8";
import ajv from "ajv-i18n";

// getting translations for json schema form error messages
export const getSchemaValidatorLocale = () => {
  const language = localStorage.getItem("i18nextLng");

  switch (language) {
    case "en":
      // @ts-ignore
      return customizeValidator({}, ajv.en);
    case "de":
      // @ts-ignore
      return customizeValidator({}, ajv.de);
    case "fr":
      // @ts-ignore
      return customizeValidator({}, ajv.fr);
    case "it":
      // @ts-ignore
      return customizeValidator({}, ajv.it);
    default:
      // @ts-ignore
      return customizeValidator({}, ajv.en);
  }
};
