export const getIconImage = (iconType: string) => {
  switch (iconType) {
    case "adult":
      return "price_full_icon.svg";
    case "infant":
      return "price_infant_icon.svg";
    case "extraPersonIcon":
      return "price_extra_icon.svg";
    case "child":
      return "price_child_icon.svg";
    case "duration":
      return "duration_icon.svg";
    case "hour":
      return "hour_icon.svg";
    case "additionalHourIcon":
      return "additional_hour_icon.svg";
    case "piece":
      return "piece_icon.svg";
    case "additionalPieceIcon":
      return "additional_piece_icon.svg";
    case "day":
      return "day_icon.svg";
    case "additionalDayIcon":
      return "additional_day_icon.svg";

    default:
      return "price_full_icon.svg";
  }
};
