import { format } from "date-fns";

export const isDateInStringIsValid = (date: any) => {
  if (typeof date === "string") {
    // @ts-ignore
    return !isNaN(new Date(format(new Date(date), "dd.MM.yyyy")));
  } else {
    return false;
  }
};
