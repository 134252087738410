import { Box } from "@mui/material";

interface SectionHeadingBoxProps {
  icon?: string;
  heading: string;
  subheading?: string;
}

export default function SectionHeadingBox({ icon, heading, subheading }: SectionHeadingBoxProps) {
  return (
    <Box className="section-heading-box" mb={{ xs: 3, lg: 4 }}>
      {icon && (
        <Box sx={{ pb: 5 }} justifyContent="center" display="flex" alignItems="center">
          <img src={icon} alt="heading-icon" />
        </Box>
      )}
      <p className="section-heading-box__subheading">{subheading}</p>
      <h2 className="section-heading-box__heading">{heading}</h2>
    </Box>
  );
}
