export const privacyLink: string | undefined = "https://getlocal.ch/datenschutz-ch";
export const termsLink: string | undefined = "https://getlocal.ch/geschaeftsbedingungen-ch";

export const getHardcodedFooterLinks = (t: any) => [
  {
    name: "footer_about",
    label: t("footer.about.label"),
    links: [
      { label: t("footer.about.howItWork"), link: "https://getlocal.ch/how-does-it-work" },
      { label: t("footer.about.faq"), link: "https://getlocal.ch/faq-ch" },
      { label: t("footer.about.contact"), link: "https://getlocal.ch/kontakt" }
    ]
  },
  {
    name: "footer_legal",
    label: t("footer.legal.label"),
    links: [
      { label: t("footer.legal.privacy"), link: privacyLink },
      { label: t("footer.legal.imprint"), link: "https://getlocal.ch/impressum" },
      { label: t("footer.legal.t&c"), link: termsLink }
    ]
  }
];
