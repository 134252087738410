import Tooltip from "@mui/material/Tooltip";

interface AppTooltipProps {
  children: JSX.Element;
  title: string;
  enterDelay?: number;
  leaveDelay?: number;
  arrow?: boolean;
  placement:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;
}

const AppTooltip = ({ children, title, enterDelay, leaveDelay, arrow, placement }: AppTooltipProps) => {
  return (
    <Tooltip
      title={title}
      enterDelay={enterDelay ? enterDelay : 1000}
      leaveDelay={leaveDelay ? leaveDelay : 200}
      arrow={arrow}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};

export default AppTooltip;
