// split sentence by words and words split by letters to push indexes of every letter that are before the given word (order of given word passed) - return length of words that are before the given word (without spaces)
export const getLengthOfStringBeforeGivenWordWithoutSpaces = (string: string, wordInOrder: number): number => {
  let indexes = [];
  for (let i = 0; i < wordInOrder; i++) {
    const val = string?.split(" ")?.[i];
    for (let j = 0; j < val?.split("")?.length; j++) {
      indexes.push(j);
    }
  }
  return indexes?.length;
};
