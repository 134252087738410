import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

interface SbbRowActionTypes {
  label: string;
  icon: string;
  actionComponent?: JSX.Element;
  isDisabled?: boolean;
}

export default function SbbRowAction({ label, icon, actionComponent, isDisabled }: SbbRowActionTypes) {
  return (
    <Box className={`sbb-row-action ${isDisabled ? "sbb-row-action__disabled" : ""}`} pb={{ xs: 5, sm: 3 }} pr={2}>
      <Grid
        item
        sm={4}
        xs={5}
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img src={icon} alt="departure" />
          <Box pt={2} sx={{ textAlign: "center", fontSize: { xs: 12, sm: 14 } }}>
            {label?.toUpperCase()}
          </Box>
        </Box>
      </Grid>
      <Grid item sm={8} xs={7} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
          {actionComponent}
        </Grid>
      </Grid>
    </Box>
  );
}
