import ApiHelper from "../../../common/utils/apiHelper";
// helpers
import { checkForValidEmail } from "../../../common/utils/helpers/checkForValidEmail";
import { checkForEmptyField } from "../../../common/utils/helpers/checkForEmptyFields";
import { removeKey } from "../../../common/utils/helpers/removeKeyFromObject";
// actions
import { globalActionCreators } from "../../../common/store/modules/globalModule";
import { confirmationActionCreators } from "../../../common/store/modules/bookingConfirmationModule";

export const sbbActionTypes = {
  CALL_SBB_API: "CALL_SBB_API",
  TOGGLE_QUICK_SELECTION_ERROR: "TOGGLE_QUICK_SELECTION_ERROR",
  UPDATE_PASSENGERS_LIST: "UPDATE_PASSENGERS_LIST",
  SET_PREBOOK_INFO_PRICE: "SET_PREBOOK_INFO_PRICE",
  UPDATE_DATA_FOR_BOOKING: "UPDATE_DATA_FOR_BOOKING",
  UPDATE_PREBOOK_API_ERROR: "UPDATE_PREBOOK_API_ERROR",
  SET_SBB_BOOKING_RESPONSE_DATA: "SET_SBB_BOOKING_RESPONSE_DATA",
  TOGGLE_SBB_BOOKING_ERROR: "TOGGLE_SBB_BOOKING_ERROR",
  TOGGLE_SBB_PAYMENT_MODAL: "TOGGLE_SBB_PAYMENT_MODAL",
  SET_ACTIVE_QUICK_SELECTION_OFFER: "SET_ACTIVE_QUICK_SELECTION_OFFER",
  RESET_SBB_STATE: "RESET_SBB_STATE"
};

export const initialState = {
  sbbData: null,
  sbbQuickSelection: [],
  sbbCustomRouteCode: null,
  quickSelectionError: false,
  sbbActiveOffer: null,
  passengerList: [
    {
      first_name: "",
      last_name: "",
      birthdate: new Date("2001-01-01"),
      member_card: false,
      hasRemove: false
    }
  ],
  prebookPriceInfo: null,
  errorPrebook: null,
  dataForBooking: {
    copyConfirmationForm: true,
    commentForm: "",
    emailForm: ""
  },
  sbbBookingResponseData: null,
  sbbBookingError: false,
  showSbbPaymentModal: false
};

const sbbModule = (state = initialState, action: any) => {
  switch (action.type) {
    case sbbActionTypes.CALL_SBB_API:
      return {
        ...state,
        sbbData: action.sbbData,
        sbbQuickSelection: action.sbbQuickSelection,
        sbbCustomRouteCode: action.sbbCustomRouteCode
      };
    case sbbActionTypes.UPDATE_PASSENGERS_LIST:
      return {
        ...state,
        passengerList: action.passengerList
      };
    case sbbActionTypes.SET_PREBOOK_INFO_PRICE:
      return {
        ...state,
        prebookPriceInfo: action.prebookPriceInfo
      };
    case sbbActionTypes.UPDATE_DATA_FOR_BOOKING:
      return {
        ...state,
        dataForBooking: action.dataForBooking
      };
    case sbbActionTypes.UPDATE_PREBOOK_API_ERROR:
      return {
        ...state,
        errorPrebook: action.errorPrebook
      };
    case sbbActionTypes.SET_SBB_BOOKING_RESPONSE_DATA:
      return {
        ...state,
        sbbBookingResponseData: action.sbbBookingResponseData
      };
    case sbbActionTypes.TOGGLE_SBB_BOOKING_ERROR:
      return {
        ...state,
        sbbBookingError: action.sbbBookingError
      };
    case sbbActionTypes.TOGGLE_SBB_PAYMENT_MODAL:
      return {
        ...state,
        showSbbPaymentModal: action.showSbbPaymentModal
      };
    case sbbActionTypes.SET_ACTIVE_QUICK_SELECTION_OFFER:
      return {
        ...state,
        sbbActiveOffer: action.sbbActiveOffer
      };
    case sbbActionTypes.RESET_SBB_STATE:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
};

export default sbbModule;

export const sbbActionCreators = {
  getSbbInfoAction: () => async (dispatch: any, getState: any) => {
    const apiUrl = getState().global.apiUrl;

    // turn on the loader
    dispatch(globalActionCreators?.toggleGlobalLoaderAction(true));
    // reset error state
    dispatch({
      type: sbbActionTypes.TOGGLE_QUICK_SELECTION_ERROR,
      quickSelectionError: false
    });

    try {
      const res = await ApiHelper.get(`${apiUrl}/transport/sbb`);

      dispatch({
        type: sbbActionTypes.CALL_SBB_API,
        sbbQuickSelection: res?.data?.items,
        sbbCustomRouteCode: res?.data?.main,
        sbbData: res?.data
      });
    } catch (err: any) {
      dispatch({
        type: sbbActionTypes.TOGGLE_QUICK_SELECTION_ERROR,
        quickSelectionError: true
      });
    } finally {
      dispatch(globalActionCreators?.toggleGlobalLoaderAction(false));
    }
  },
  searchSbbStationsAction: (query: string) => async (dispatch: any, getState: any) => {
    const apiUrl = getState().global.apiUrl;

    if (query?.length > 2) {
      try {
        const res = await ApiHelper.get(`${apiUrl}/transport/sbb/stations?stationName=${query}`);
        return res?.data;
      } catch (err: any) {}
    }
  },
  updatePassengersAction: (passengerList: Array<any>) => (dispatch: any) => {
    dispatch({
      type: sbbActionTypes.UPDATE_PASSENGERS_LIST,
      passengerList
    });
  },
  getPriceSbbPrebookAction: (params: any) => async (dispatch: any, getState: any) => {
    const apiUrl = getState().global.apiUrl;
    const passengerListState = getState().sbb.passengerList;

    const apiParams = {
      passengers: removeKey("hasRemove", passengerListState),
      ...params
    };

    // turn on the loader
    dispatch(globalActionCreators?.toggleGlobalLoaderAction(true));
    // reset error state
    dispatch({
      type: sbbActionTypes.UPDATE_PREBOOK_API_ERROR,
      errorPrebook: null
    });

    try {
      const res = await ApiHelper.post(`${apiUrl}/transport/sbb/prebook`, {}, apiParams);
      dispatch({
        type: sbbActionTypes.SET_PREBOOK_INFO_PRICE,
        prebookPriceInfo: res?.data
      });
      return res?.data;
    } catch (err: any) {
      dispatch({
        type: sbbActionTypes.SET_PREBOOK_INFO_PRICE,
        prebookPriceInfo: null
      });
      dispatch({
        type: sbbActionTypes.UPDATE_PREBOOK_API_ERROR,
        errorPrebook: err?.response.data
      });
    } finally {
      dispatch(globalActionCreators?.toggleGlobalLoaderAction(false));
    }
  },
  resetPrebookDataInfoAction: () => (dispatch: any) => {
    dispatch({
      type: sbbActionTypes.SET_PREBOOK_INFO_PRICE,
      prebookPriceInfo: null
    });
  },
  updateFormForBookingAction:
    (dataForBooking: { copyConfirmationForm: boolean; commentForm: string; emailForm: string }) => (dispatch: any) => {
      dispatch({
        type: sbbActionTypes.UPDATE_DATA_FOR_BOOKING,
        dataForBooking: dataForBooking
      });
    },
  toggleSbbBookingErrorAction: (sbbBookingError: boolean) => (dispatch: any) => {
    dispatch({
      type: sbbActionTypes.TOGGLE_SBB_BOOKING_ERROR,
      sbbBookingError
    });
  },
  buySbbTicketAction: (params: any, navigate: any) => async (dispatch: any, getState: any) => {
    const dataForBookingState = getState().sbb.dataForBooking;
    const passengerListState = getState().sbb.passengerList;
    const prebookPriceInfoState = getState().sbb.prebookPriceInfo;

    // remove bookingResponseDataStorage from storage, before setting it again
    localStorage.removeItem("bookingResponseDataStorage");

    let errorField = {
      emailForm: false,
      commentForm: false
    };
    let errorCheck = true;

    if (checkForValidEmail(dataForBookingState.emailForm) || checkForEmptyField(dataForBookingState.emailForm)) {
      errorField.emailForm = true;
      errorCheck = false;
    }

    if (dataForBookingState?.commentForm !== "" && dataForBookingState?.commentForm?.length < 3) {
      errorField.commentForm = true;
      errorCheck = false;
    }

    if (!errorCheck) {
      dispatch({ type: "TOGGLE_ERROR_FIELD", errorExists: true, errorField: errorField });
    } else {
      const apiUrl = getState().global.apiUrl;
      const language = localStorage.getItem("i18nextLng");

      dispatch(globalActionCreators?.toggleGlobalLoaderAction(true));
      dispatch({ type: "TOGGLE_ERROR_FIELD", errorExists: false, errorField: errorField });

      const apiParams = {
        summary: {
          start_date: params?.start_date,
          adult: passengerListState?.length,
          timeslot: params?.timeslot,
          offer_code: params?.offer_code
        },
        personal_information: {
          first_name: passengerListState[0]?.first_name,
          last_name: passengerListState[0]?.last_name,
          email: dataForBookingState?.emailForm,
          phone: "123",
          comment: dataForBookingState?.commentForm ? dataForBookingState?.commentForm : null
        },
        hotel_stay_code: getState().hotel.hotelInfo?.code,
        session_id: prebookPriceInfoState?.session_id
      };

      try {
        const response = await ApiHelper.post(`${apiUrl}/bookings`, {}, apiParams);

        localStorage.setItem("bookingResponseDataStorage", JSON.stringify(response.data));

        // We can get API status 200, with booking_status=error --> in that case we should show error page instead of Payment Modal / Confirmation Page
        if (response.data?.booking_status === "error") {
          dispatch(sbbActionCreators?.toggleSbbBookingErrorAction(true));
          return;
        } else {
          dispatch(sbbActionCreators?.toggleSbbBookingErrorAction(false));

          dispatch({
            type: sbbActionTypes.SET_SBB_BOOKING_RESPONSE_DATA,
            sbbBookingResponseData: response.data
          });

          // If price is 0 we will get payment_status=paid from API --> and we should redirect user directly to the /confirmation page (without payment modal)
          if (response.data?.payment_status === "paid") {
            // call booking status action, in order to set new value to the bookingStatus state
            await dispatch(confirmationActionCreators?.getBookingStatusAction());
            // redirect to confirmation
            await navigate(`/${language}/transport/sbb/confirmation`, { replace: true });
          } else {
            // open payment modal (if status is not booked)
            dispatch(sbbActionCreators?.toggleSbbPaymentModalAction(true));
          }
        }
      } catch (e) {
        dispatch(sbbActionCreators?.toggleSbbBookingErrorAction(true));
      } finally {
        dispatch(globalActionCreators?.toggleGlobalLoaderAction(false));
      }
    }
  },
  toggleSbbPaymentModalAction: (showSbbPaymentModal: boolean) => (dispatch: any) => {
    dispatch({
      type: sbbActionTypes.TOGGLE_SBB_PAYMENT_MODAL,
      showSbbPaymentModal
    });
  },
  setSbbActiveOfferAction: (sbbActiveOffer: any) => (dispatch: any) => {
    dispatch({
      type: sbbActionTypes.SET_ACTIVE_QUICK_SELECTION_OFFER,
      sbbActiveOffer
    });
  },
  resetSbbStateAction: () => (dispatch: any) => {
    dispatch({
      type: sbbActionTypes.RESET_SBB_STATE
    });
  }
};
