// TODO: write test for this method

export const checkForEmptyNames = (passengerList: Array<any>): boolean => {
  for (let passenger of passengerList) {
    if (passenger?.first_name?.length < 2 || passenger?.last_name?.length < 2) {
      return true;
    }
  }
  return false;
};
