export const updateMinutes = (
  setMinutes: (minutes: number) => any,
  setHours: (hours: number) => any,
  change: string,
  amount: number | string,
  previousHours: any,
  previousMinutes: any
) => {
  const newAmount = Number(amount);

  const currentMinutes = parseInt(previousMinutes, 10);
  const currentHours = parseInt(previousHours, 10);

  if (change === "initial") {
    setMinutes(currentMinutes > 44 ? (currentMinutes == 45 ? 0 : currentMinutes - 45) : currentMinutes + newAmount);
    setHours(currentMinutes > 44 ? currentHours + 1 : previousHours == "0" ? 0 : currentHours);
  } else if (change === "increase") {
    const hours =
      currentMinutes > 54 ? (currentHours > 22 ? 0 : currentHours + 1) : previousHours == "0" ? 0 : currentHours;
    const minutes = currentMinutes > 54 ? 0 : currentMinutes + newAmount - (currentMinutes % 5);

    setMinutes(minutes);
    setHours(hours);
  } else if (change === "decrease") {
    const hours =
      currentMinutes < 5
        ? currentHours < 2
          ? currentHours < 1
            ? 23
            : 0
          : currentHours - 1
        : previousHours == "0"
        ? 0
        : currentHours;
    const minutes =
      currentMinutes < 6 ? (currentMinutes == 5 ? 0 : 55) : currentMinutes - newAmount - (currentMinutes % 5);

    setMinutes(minutes);
    setHours(hours);
  } else {
    setMinutes(newAmount > 59 ? 59 : newAmount);
  }
};
