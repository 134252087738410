import axios from "axios";

const appHeaders = {
  "Content-Type": "application/json"
};

// // Create a custom Axios instance
export const axiosInstance = axios.create({
  // baseURL: "https://localhost" // Set your API base URL
});

const createQueryString = (params: any) => {
  const paramsKeys = Object.keys(params);
  let queryString = "";
  paramsKeys.forEach((key, index) => {
    if (Array.isArray(params[key])) {
      if (params[key].length > 0) {
        queryString += index === 0 ? "?" : "&";
        params[key].forEach((el: any, i: any) => {
          if (i > 0) {
            queryString += "&";
          }
          queryString += `${encodeURIComponent(`${key}[${i}]`)}=${encodeURIComponent(el)}`;
        });
      }
    } else {
      queryString += index === 0 ? "?" : "&";
      queryString += `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    }
  });
  return queryString;
};

class ApiHelper {
  static async get(resourceUrl: string, params = {}, additionalHeaders?: object, responseType?: any) {
    const url = `${resourceUrl + createQueryString(params)}`;
    return axiosInstance({
      method: "get",
      url,
      headers: {
        ...appHeaders,
        ...additionalHeaders
      },
      responseType: responseType
    });
  }

  static async post(resourceUrl: string, params = {}, bodyData: any) {
    const url = `${resourceUrl + createQueryString(params)}`;
    return axiosInstance({
      method: "post",
      url,
      data: bodyData,
      headers: appHeaders
    });
  }

  static async put(resourceUrl: string, bodyData: any) {
    const url = `${resourceUrl}`;
    return axiosInstance({
      method: "put",
      url,
      data: bodyData,
      headers: appHeaders
    });
  }

  static async patch(resourceUrl: string, params = {}, bodyData: any) {
    const url = `${resourceUrl + createQueryString(params)}`;
    return axiosInstance({
      method: "patch",
      url,
      data: bodyData,
      headers: appHeaders
    });
  }

  static async delete(resourceUrl: string) {
    const url = `${resourceUrl}`;
    return axiosInstance({
      method: "delete",
      url,
      headers: appHeaders
    });
  }
}

export default ApiHelper;
