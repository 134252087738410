import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ObjectFieldTemplatePropertyType, ObjectFieldTemplateProps } from "@rjsf/utils";

export default function SummaryFieldsTemplate(props: ObjectFieldTemplateProps) {
  return (
    <Grid container>
      <Grid item xs={12} pr={{ xs: 0, sm: 1, md: 2 }}>
        {props.properties?.map((element: ObjectFieldTemplatePropertyType) => (
          <Box key={element.name} className="property-wrapper" mt={2}>
            {element.content}
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
