interface AppInputProps {
  name?: string;
  type?: string;
  disabled?: boolean;
  value: string;
  onChange: (event: any) => void;
  required?: boolean;
  error?: boolean;
  rows?: number;
  dataAttribute?: string;
}

export default function AppFormTextarea({
  name,
  type,
  disabled,
  value,
  onChange,
  required,
  error,
  rows,
  dataAttribute,
  ...props
}: AppInputProps) {
  return (
    <textarea
      {...props}
      name={name}
      disabled={disabled}
      value={value}
      onChange={onChange}
      required={required}
      rows={rows ? rows : 4}
      className={`input input--gray input--textarea ${error ? "invalid__input" : ""}`}
      data-cy={dataAttribute ? dataAttribute : ""}
    />
  );
}
