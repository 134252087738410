import { useState, useRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, parseISO } from "date-fns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Box from "@mui/material/Box";
// helpers
import { getMonthInFuture } from "../../common/utils/helpers/getMonthInFuture";
import { getDateFnsLocale } from "../../common/utils/helpers/localeHelpers/getDateFnsLocale";

// AppDatePicker for json schema forms

export default function AppDatePicker(props: any) {
  const [selectedDate, setSelectedDate] = useState(parseISO(props.formData));
  const currentDate = new Date();
  const sixMonthInFutureDate = getMonthInFuture(6);
  const inputRef = useRef(null);

  const handleDateChange = (date: any) => {
    // TBD: Move this to helper
    const inputDate = new Date(date);
    // Format the parsed date in the 'dd-MM-yyyy' default format
    const formattedDate = format(inputDate, "yyyy-MM-dd");
    setSelectedDate(date);
    props.onChange(formattedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateFnsLocale()}>
      <Box>
        <Box className="form-group form-group--vertical date-picker-box">
          <label className="form-group__label form-group__label--white"> {props?.schema?.title} </label>

          <CalendarTodayIcon
            style={{ position: "absolute", right: 10, top: "75%", transform: "translateY(-50%)", cursor: "pointer" }}
            onClick={handleDateChange}
            data-cy="calendar-icon"
          />
          <DatePicker
            minDate={currentDate}
            maxDate={sixMonthInFutureDate}
            value={selectedDate}
            onChange={handleDateChange}
            ref={inputRef.current}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
