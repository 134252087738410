import LoadingButton from "@mui/lab/LoadingButton";
// @ts-ignore
import { useTheme } from "@mui/material/styles";

interface AppButtonProps {
  label: string;
  onClick: (e: any) => void;
  disabled?: boolean;
  variant?: "text" | "outlined" | "contained" | undefined;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
  labelColor?: string;
  customBackgroundColor?: string;
  customActiveColor?: string;
  customHoverColor?: string;
  customClickColor?: string;
  disableRipple?: boolean;
  isLoading?: boolean;
  type?: "submit" | "reset" | "button" | string | undefined | any;
  fontSize?: string;
  id?: string;
  dataAttribute?: string;
}

export default function AppButton({
  label,
  onClick,
  disabled,
  variant,
  color,
  labelColor,
  customBackgroundColor,
  customActiveColor,
  customHoverColor,
  customClickColor,
  disableRipple,
  isLoading,
  type,
  fontSize,
  id,
  dataAttribute
}: AppButtonProps) {
  const theme = useTheme();

  return (
    <LoadingButton
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      color={color}
      disableRipple={disableRipple}
      loading={isLoading}
      type={type}
      sx={{
        backgroundColor: customBackgroundColor,
        color: labelColor || theme.palette.white.main,
        width: "100%",
        height: "100%",
        fontSize: fontSize ? fontSize : "16px",
        lineHeight: "16px",
        fontFamily: "Neutraface2Demi",
        padding: "12px 16px",
        // Fix for vertical alignment because of font (Neutraface2Demi) issue
        paddingBottom: "11px",
        paddingTop: "13px",
        //
        fontWeight: 100,
        borderRadius: "5px",
        ":hover": {
          backgroundColor: customHoverColor
        },
        ":active": {
          backgroundColor: customClickColor
        },
        // Fix for mobile - on scroll btn background color inherit hover color
        "@media screen and (max-width: 600px)": {
          ":hover": {
            backgroundColor: customBackgroundColor
          }
        },
        "& .MuiCircularProgress-colorInherit": {
          color: labelColor || theme.palette.white.main
        }
        // TBD: Check for this color customActiveColor for active functionality in the future
      }}
      id={id}
      data-cy={dataAttribute ? dataAttribute : ""}
    >
      {label}
    </LoadingButton>
  );
}
